import { Icon, useMediaQuery } from 'native-base';
import React, { FC } from 'react';
import { SCREENSIZE } from '../../global';
import { ReactComponent as Lock } from '../../assets/icons/lock.svg';
import { ReactComponent as LockOpen } from '../../assets/icons/lock_open.svg';

interface LockIconProps {
    lock?: boolean;
}

const LockIcon: FC<LockIconProps> = ({ lock = false }) => {
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });
    const icon = lock ? Lock : LockOpen;

    return <Icon as={icon} size={isSmallScreen ? 3 : 4} />;
};

export default LockIcon;
