import { Box, Button, ChevronLeftIcon, ChevronRightIcon, Flex, Heading, HStack, Spinner, Text } from 'native-base';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import ButtonIcon from '../Button/ButtonIcon';
import { ColumnsDescription, DatumBase } from './types';
import styles from './Table.module.css';

export interface TableProps<T extends DatumBase> {
    title: string;
    asideTitle?: ReactNode;
    noDataLabel: string;
    columns: ColumnsDescription<T>;
    loading: boolean;
    data: T[];
    itemsTotal: number;
    itemsPerPage?: number;
    pageRangeDisplayed?: number;
    loadMoreItems: () => void;
}

const Table = <T extends DatumBase>({
    title,
    asideTitle,
    noDataLabel,
    columns,
    loading,
    data = [],
    itemsTotal,
    loadMoreItems,
    itemsPerPage = 20,
    pageRangeDisplayed = 5,
}: TableProps<T>) => {
    const pages = useMemo(
        () =>
            Array.from({ length: Math.ceil(itemsTotal / itemsPerPage) }, (_, i) =>
                data.slice(i * itemsPerPage, i * itemsPerPage + itemsPerPage),
            ),
        [itemsTotal, itemsPerPage, data],
    );

    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const currentPage = useMemo(() => pages.at(currentPageIndex), [data, currentPageIndex]);
    const shouldLoadMore = useMemo(
        () => Math.ceil(data.length - currentPageIndex * itemsPerPage) < itemsPerPage,
        [data, currentPageIndex, itemsPerPage],
    );

    const columnsKeys: (keyof ColumnsDescription<T>)[] = Object.keys(columns) as (keyof ColumnsDescription<T>)[];

    useEffect(() => {
        if (shouldLoadMore) {
            loadMoreItems();
        }
    }, [loadMoreItems, shouldLoadMore]);

    const headers = columnsKeys.map((index) => (
        <th key={index.toString()} className={styles.th}>
            <Text fontSize={11} fontWeight={500} lineHeight="1.6em">
                {columns[index].name}
            </Text>
        </th>
    ));
    const formatRowData = (idx: Exclude<keyof T, 'id'>, datum: any) => {
        if (typeof columns[idx].formatData === 'function') {
            return columns[idx].formatData!(datum[idx], datum);
        }

        return datum?.[idx];
    };

    const renderTableCharges = useMemo((): JSX.Element | undefined => {
        if (loading) {
            return (
                <tr>
                    <td colSpan={columnsKeys.length}>
                        <Spinner alignSelf="center" m={4} size="lg" />
                    </td>
                </tr>
            );
        }
        if (currentPage && currentPage.length > 0) {
            return (
                <>
                    {currentPage.map((datum) => (
                        <tr key={datum.id} data-testid="tr">
                            {columnsKeys.map((index) => (
                                <td key={index.toString()} className={styles.td}>
                                    <Text fontSize={14} fontWeight={500}>
                                        {formatRowData(index, datum)}
                                    </Text>
                                </td>
                            ))}
                        </tr>
                    ))}
                </>
            );
        }

        return undefined;
    }, [loading, currentPage]);

    return (
        <Flex flex="1" flexDirection="column">
            <HStack alignItems="center" justifyContent="space-between" mb={7}>
                <Heading fontSize={19} fontWeight={500} mr={2} size="md">
                    {title}
                </Heading>
                {asideTitle}
            </HStack>
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead className={styles.thead}>
                        <tr>{headers}</tr>
                    </thead>

                    <tbody className={styles.tbody}>{renderTableCharges}</tbody>
                </table>
                {data !== undefined && data.length === 0 ? (
                    <Box alignItems="center" py={6}>
                        <Text>{noDataLabel}</Text>
                    </Box>
                ) : null}
            </div>

            <HStack alignItems="center" justifyContent="center" my={4} space={2}>
                <ButtonIcon
                    icon={<ChevronLeftIcon />}
                    isDisabled={currentPageIndex === 0}
                    onPress={() => setCurrentPageIndex(currentPageIndex - 1)}
                />
                {pages.map((_, index) => {
                    const treshold = pageRangeDisplayed / 2;
                    if (
                        index === 0 ||
                        index === pages.length - 1 ||
                        Math.abs(index - currentPageIndex) <= Math.floor(treshold)
                    ) {
                        return (
                            <Button
                                key={Math.random()}
                                _dark={{ _text: { color: 'white' } }}
                                h="43px"
                                onPress={() => setCurrentPageIndex(index)}
                                variant={index === currentPageIndex ? 'solid' : 'outline'}
                                w="43px"
                            >
                                {index + 1}
                            </Button>
                        );
                    }
                    if (Math.abs(index - currentPageIndex) <= Math.ceil(treshold)) {
                        return <Text key={Math.random()}>...</Text>;
                    }

                    return null;
                })}
                <ButtonIcon
                    icon={<ChevronRightIcon />}
                    isDisabled={currentPageIndex === pages.length - 1}
                    onPress={() => setCurrentPageIndex(currentPageIndex + 1)}
                />
            </HStack>
        </Flex>
    );
};

export default Table;
