import { Modal, Text } from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GradientButton from '../../../components/Button/GradientButton/GradientButton';
import handleError from '../../../components/ErrorToast/ErrorToast';
import { invokeSuccessToast } from '../../../components/Toast/Toast';
import providers from '../../../providers';
import { companySelect } from '../../../store/auth/auth';
import { useAppSelector } from '../../../store/hooks';
import { UserDetails } from '../../UserDetails/types';

/**
 * Form used to delete a user
 */
const DeleteUserModalContent = ({
    user,
    onSubmitted,
}: {
    user?: UserDetails; // The user we want to delete
    onSubmitted: VoidFunction;
}) => {
    const { t } = useTranslation();

    const company = useAppSelector(companySelect);
    const [isRequestPending, setIsRequestPending] = useState<boolean>(false);

    /**
     * Displays a toast about the success of form submission.
     * @param userName the user name displayed in the toast.
     */
    const displaySuccessToast = (userName: string) =>
        invokeSuccessToast(t('common.success.title'), t('userDetails.delete.success.description', { userName }));

    const onValidate = () => {
        if (company && user) {
            setIsRequestPending(true);
            providers.usersProvider
                .deleteUser(company.id, {
                    id: user.id,
                    communities: [],
                    email: user.email,
                    firstName: user.firstName,
                    isManager: false,
                    lastName: user.lastName,
                    phoneNumber: user.phoneNumber,
                })
                .then(() => {
                    displaySuccessToast(`${user.firstName} ${user.lastName}`);
                    onSubmitted();
                })
                .catch(handleError)
                .finally(() => setIsRequestPending(false));
        }
    };

    return (
        <>
            <Modal.Body>
                <Text>
                    {t('userDetails.delete.text', {
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                    })}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <GradientButton
                    fontSize="sm"
                    isLoading={isRequestPending}
                    label={t('userDetails.delete.validate')}
                    onClick={onValidate}
                />
            </Modal.Footer>
        </>
    );
};

export default DeleteUserModalContent;
