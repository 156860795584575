import { Badge, Box, HStack, IBoxProps, Icon, usePropsResolution } from 'native-base';
import React, { FC } from 'react';
import { Community } from '../../types';
import { ReactComponent as IconCommunity } from '../../assets/icons/community.svg';

interface CommunityBadgeProps extends IBoxProps<CommunityBadgeProps> {
    community: Community;
}

const CommunityBadge: FC<CommunityBadgeProps> = ({ community, ...rest }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const resolvedProps = usePropsResolution('CommunityBadge', rest);

    return (
        <Badge
            key={community.id}
            _text={{ color: 'white', fontSize: 10 }}
            bg="primary.700"
            color="white"
            flexDirection="row"
            variant="solid"
            {...resolvedProps}
        >
            <HStack alignItems="center" justifyContent="center">
                <Icon mr={2} size={3}>
                    <IconCommunity />
                </Icon>
                <Box _text={{ fontSize: 12 }}>{community.name}</Box>
            </HStack>
        </Badge>
    );
};

export default CommunityBadge;
