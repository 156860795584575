import { Box, ITextProps, Spinner, useMediaQuery, useTheme, Image } from 'native-base';
import React from 'react';
import { SCREENSIZE } from '../../../global';
import styles from './GradientButton.module.css';

/* eslint-disable react/button-has-type */

export interface GradientButtonProps extends React.ComponentPropsWithoutRef<'button'> {
    isLoading?: boolean;
    label: string | JSX.Element;
    onClick?: VoidFunction;
    fontSize?: ITextProps['fontSize'];
    icon?: JSX.Element;
    iconRight?: string;
}

const GradientButton = ({
    label,
    isLoading,
    onClick,
    fontSize = 'md',
    type = 'button',
    icon,
    iconRight,
}: GradientButtonProps) => {
    const {
        colors: { primary },
    } = useTheme();
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    return (
        <button
            className={styles.container}
            onClick={isLoading ? undefined : onClick}
            style={{
                background: `linear-gradient(60.31deg, ${primary['700']} 6.21%, ${primary['300']} 100%)`,
                width: isSmallScreen && icon ? '42px' : isSmallScreen ? '100%' : undefined,
                minWidth: isSmallScreen && icon ? '42px' : 'max-content',
            }}
            type={type}
        >
            <Box
                _text={{
                    fontSize,
                    fontWeight: 'medium',
                    textAlign: 'center',
                }}
                alignItems="center"
                flexDir={iconRight ? 'row' : undefined}
                m={[0, 2]}
                px={icon ? 0 : 4}
            >
                {isLoading ? <Spinner size="sm" /> : isSmallScreen && icon ? icon : label}
                {iconRight ? <Image alt="Avatar" ml={3} size={4} src={iconRight} /> : undefined}
            </Box>
        </button>
    );
};

export default GradientButton;
