import { Icon, useMediaQuery } from 'native-base';
import React, { FC } from 'react';
import { SCREENSIZE } from '../../global';
import ButtonIcon, { ButtonIconProps } from './ButtonIcon';
import OutlinedButton from './OutlinedButton';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-outline.svg';

interface EditButtonProps {
    label: string;
    disabled?: boolean;
    mini?: boolean;
    variant?: ButtonIconProps['variant'];
    onPress: () => void;
}

const EditButton: FC<EditButtonProps> = ({ label, onPress, variant, disabled = false, mini = false }) => {
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    return isSmallScreen || mini ? (
        <ButtonIcon
            aria-label="Edit-icon"
            data-testid="edit-icon"
            icon={
                <Icon color="gray.50">
                    <EditIcon />
                </Icon>
            }
            isDisabled={disabled}
            onPress={onPress}
            size="35px"
            testID="edit-button"
            variant={variant}
        />
    ) : (
        <OutlinedButton label={label} onPress={onPress} />
    );
};

export default EditButton;
