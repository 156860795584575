import { Alert, Box, Center, CloseIcon, HStack, Text, VStack } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import ButtonIcon from '../../components/Button/ButtonIcon';
import { isAuthedSelect } from '../../store/auth/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { deleteError, errorSelect, loginStepSelect } from '../../store/login/login';
import { LoginErrors, LoginSteps } from '../../store/login/types';
import OtpPart from './OtpPart';
import PhoneNumberPart from './PhoneNumberPart';
import logoFull from '../../assets/images/logo.svg';

const LoginPage = () => {
    const { t } = useTranslation();
    const isAuthed = useAppSelector(isAuthedSelect);
    const dispatch = useAppDispatch();
    const loginStep = useAppSelector(loginStepSelect);
    const error = useAppSelector(errorSelect);

    const location = useLocation();
    const from = (location.state as { from: Location } | undefined)?.from?.pathname || '/';

    const getErrorMessage = (err: string) => {
        switch (err) {
            case LoginErrors.INCORRECT_CODE:
                return t('login.error.INCORRECT_CODE');
            case LoginErrors.NO_ACCESS:
                return t('login.error.NO_ACCESS');
            case LoginErrors.NO_MANAGER_ROLE:
                return t('login.error.NO_MANAGER_ROLE');
            default:
                return t('login.error.fallback');
        }
    };

    if (isAuthed) {
        return <Navigate to={from} replace />;
    }

    return (
        <Center height="100vh">
            <Box bg="backgroundLevel1.primary" borderRadius={30} maxW={['90%', null]} minW={[null, 460]} padding="2rem">
                <Box mb={8} ml="auto" mr="auto" width="200px">
                    <img alt="Logo" src={logoFull} />
                </Box>

                {error ? (
                    <Alert mb={4} status="error" w="100%">
                        <VStack flexShrink={1} space={2} w="100%">
                            <HStack flexShrink={1} justifyContent="space-between" space={2}>
                                <HStack flexShrink={1} space={2}>
                                    <Alert.Icon mt="1" />
                                    <Text color="coolGray.800" fontSize="sm">
                                        {getErrorMessage(error)}
                                    </Text>
                                </HStack>
                                <ButtonIcon
                                    icon={<CloseIcon color="coolGray.600" size="3" />}
                                    onPress={() => dispatch(deleteError())}
                                    testID="alert-error-close-btn"
                                    variant="unstyled"
                                />
                            </HStack>
                        </VStack>
                    </Alert>
                ) : null}

                {loginStep === LoginSteps.PhoneNumber ? <PhoneNumberPart /> : <OtpPart />}
            </Box>
        </Center>
    );
};

export default LoginPage;
