import { HStack, Spinner, VStack } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import DashboardTile from './DashboardTile/DashboardTile';
import { DashboardData, DashboardWSOutput } from './types';

/**
 * Build a string representing a duration in a comprehensive format.
 * @param minuteNb The number of minutes.
 */
const humanizeDuration = (minuteNb: number) => {
    const hours = Math.trunc(minuteNb / 60);
    const minutes = minuteNb % 60;

    return `${hours}h${minutes}`;
};

const HomePage = () => {
    const { t } = useTranslation();
    const [data, setData] = useState<DashboardData | undefined>(undefined);
    const company = useAppSelector(companySelect);

    const fetchData = () => {
        providers.httpService
            .get<DashboardWSOutput>(`/api/v1/manager/${company?.id}/dashboard`)
            .then((res) =>
                setData({
                    communitiesCount: res.communitiesCount,
                    consumedEnergy: res.consumedEnergy,
                    siteCount: res.sitesCount,
                    sockets: {
                        inCharge: res.socketsInChargeCount,
                        total: res.socketsCount,
                    },
                    userCount: res.usersCount,
                    monthlyChargeDuration: res.monthlyChargeDuration,
                }),
            )
            .catch(console.warn);
    };

    useEffect(() => {
        if (company) {
            fetchData();
        }
    }, [company]);

    return (
        <VStack h="full" m={8}>
            <PageLayoutHeader title={t('dashboard.title')} />
            <HStack width="100%" zIndex={-1}>
                {data ? (
                    <HStack flexWrap="wrap" h="100%" justifyContent={['center', null]} width="100%">
                        <DashboardTile
                            // subtitle={t('dashboard.communityNumber.subtitle', { count: data.communitiesCount })}
                            title={t('dashboard.communityNumber.title')}
                            value={data.communitiesCount}
                        />
                        <DashboardTile
                            subtitle={t('dashboard.userNumber.subtitle', { count: data.userCount })}
                            title={t('dashboard.userNumber.title')}
                            value={data.userCount}
                        />
                        <DashboardTile
                            subtitle={t('dashboard.siteNumber.subtitle', { count: data.siteCount })}
                            title={t('dashboard.siteNumber.title')}
                            value={data.siteCount}
                        />
                        <DashboardTile
                            proportion={data.sockets.total}
                            subtitle={t('dashboard.socketNumber.subtitle', { count: data.sockets.inCharge })}
                            title={t('dashboard.socketNumber.title')}
                            value={data.sockets.inCharge}
                        />
                        <DashboardTile
                            subtitle={t('dashboard.chargeDuration.subtitle')}
                            title={t('dashboard.chargeDuration.title')}
                            value={humanizeDuration(data.monthlyChargeDuration)}
                        />
                        <DashboardTile
                            subtitle={t('dashboard.consumedEnergy.subtitle')}
                            title={t('dashboard.consumedEnergy.title')}
                            value={data.consumedEnergy.toFixed(2)}
                        />
                    </HStack>
                ) : (
                    <Spinner m="auto" size="lg" />
                )}
            </HStack>
        </VStack>
    );
};

export default HomePage;
