import { t } from 'i18next';
import { Badge } from 'native-base';
import React from 'react';

const statusColors: { [key: string]: [string, string] } = {
    Restricted: ['#da859f', '#ffe7f2'],
    RestrictedSoon: ['#a82c01', '#fcedb9'],
    Pending: ['#585d6d', '#ebeef1'],
    Enabled: ['#5946db', '#f2ebff'],
    Complete: ['#026b0e', '#d7f7c2'],
    Unknown: ['#545969', '#ebeef1'],
};

const StripeBadge = ({ status }: { status: string }) =>
    statusColors[status] && (
        <Badge _text={{ color: statusColors[status][0] }} bg={statusColors[status][1]} borderRadius={20} h={5}>
            {t(`companyConfiguration.statusAccountStripe.${status}`)}
        </Badge>
    );

export default StripeBadge;
