import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SiteCard from '../../components/Cards/SiteCard';
import { SiteSchema } from '../../utils/search-schema';
import PageList from '../base/PageList';
import { BaseListProps } from '../base/types';
import { Site } from './types';

interface SiteListProps extends BaseListProps<Site> {
    count: number;
}

const SiteList: FC<SiteListProps> = ({ list, count, withSearch = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const List = PageList<Site>;

    return (
        <List
            list={list}
            renderChild={({ id, communities, name, type }) => (
                <SiteCard
                    key={id}
                    communities={communities}
                    name={name}
                    onTouch={() => {
                        navigate(`/sites/${id}`);
                    }}
                    type={type}
                />
            )}
            searchSchema={SiteSchema}
            title={t('sites.sitesCount', { count })}
            withSearch={withSearch}
        />
    );
};

export default SiteList;
