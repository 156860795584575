import { ObjectLiteral } from '@sparklin/react.api.hooks.use-pagination/dist/use-pagination';
import axios, { AxiosInstance } from 'axios';
import { getI18n } from 'react-i18next';
import { isAxiosError, NetworkException } from '../providers/types';

export const baseUrl = () => process.env.REACT_APP_DOMAIN ?? undefined;

class HttpService {
    private axiosInstance: AxiosInstance;

    private injectTokenInterceptor: number | undefined;

    private dispatchDisconnectInterceptor: number | undefined;

    constructor() {
        // The URL of the back-end is "auto-injected" by CRA when in production, because the front-end is located at the same place as the back-end.
        // But when in development, we need to inject the URL by hand, and we use an env var for that (see file `.env.development`).
        this.axiosInstance = axios.create({
            baseURL: baseUrl(),
        });
    }

    setInterceptors = (token: string, disconnect: VoidFunction) => {
        // Adding an interceptor to inject the authentication token on outgoing requests
        this.injectTokenInterceptor = this.axiosInstance.interceptors.request.use((config) => {
            config.headers.setAuthorization(`Bearer ${token}`);
            config.headers.set('x-custom-lang', getI18n().language);

            return config;
        });

        // Adding an interceptor to handle the case when the response is a 401 Unauthorized
        this.dispatchDisconnectInterceptor = this.axiosInstance.interceptors.response.use(undefined, (error) => {
            if (axios.isAxiosError(error) && (error.response?.status === 401 || error.response?.status === 403)) {
                // TODO PRE 2022-03-29 See to add a message to display to users, to say they were disconnected from the app (because of not connected or not the Manager access).
                disconnect();
            }

            return Promise.reject(error);
        });
    };

    removeInterceptors = () => {
        if (this.injectTokenInterceptor !== undefined) {
            this.axiosInstance.interceptors.request.eject(this.injectTokenInterceptor);
        }

        if (this.dispatchDisconnectInterceptor !== undefined) {
            this.axiosInstance.interceptors.response.eject(this.dispatchDisconnectInterceptor);
        }
    };

    get = async <T>(path: string, params?: ObjectLiteral): Promise<T> => {
        try {
            const { data } = await this.axiosInstance.get<T>(path, { params });

            return data;
        } catch (e) {
            if (isAxiosError(e)) {
                throw new NetworkException(e.response?.status, e.message, e.response?.data);
            } else {
                throw e;
            }
        }
    };

    post = async <T, K>(path: string, payload: K, returnAsBlob = false): Promise<T> => {
        try {
            const { data } = await this.axiosInstance.post(path, payload, {
                responseType: returnAsBlob ? 'blob' : 'json',
            });

            return data;
        } catch (e) {
            if (isAxiosError<any>(e)) {
                const businessErrorMessage: string | undefined = e.response?.data.message?.join(' / ');
                throw new NetworkException(
                    e.response?.status,
                    `${e.message} : ${businessErrorMessage}`,
                    e.response?.data,
                );
            } else {
                throw e;
            }
        }
    };

    postBlob = async <K>(path: string, payload: K): Promise<{ data: Blob; fileName: string | undefined }> => {
        try {
            const { data, status, headers } = await this.axiosInstance.post(path, payload, {
                responseType: 'blob',
            });

            if (status === 204) {
                throw new NetworkException(status, 'No content', undefined);
            }

            return { data, fileName: headers['x-suggested-filename'] };
        } catch (e) {
            if (isAxiosError<any>(e)) {
                const businessErrorMessage: string | undefined = e.response?.data.message?.join(' / ');
                throw new NetworkException(
                    e.response?.status,
                    `${e.message} : ${businessErrorMessage}`,
                    e.response?.data,
                );
            } else {
                throw e;
            }
        }
    };

    put = async <T, K>(path: string, payload: K): Promise<T> => {
        try {
            const { data } = await this.axiosInstance.put(path, payload);

            return data;
        } catch (e) {
            if (isAxiosError<any>(e)) {
                const businessErrorMessage: string | undefined = e.response?.data.message?.join(' / ');
                throw new NetworkException(
                    e.response?.status,
                    `${e.message} : ${businessErrorMessage}`,
                    e.response?.data,
                );
            } else {
                throw e;
            }
        }
    };

    patch = async <T, K>(path: string, payload: K): Promise<T> => {
        try {
            const { data } = await this.axiosInstance.patch(path, payload);

            return data;
        } catch (e) {
            if (isAxiosError<any>(e)) {
                const businessErrorMessage: string | undefined = e.response?.data.message?.join(' / ');
                throw new NetworkException(
                    e.response?.status,
                    `${e.message} : ${businessErrorMessage}`,
                    e.response?.data,
                );
            } else {
                throw e;
            }
        }
    };

    delete = async <T>(path: string): Promise<T> => {
        try {
            const { data } = await this.axiosInstance.delete(path);

            return data;
        } catch (e) {
            if (isAxiosError<any>(e)) {
                const businessErrorMessage: string | undefined = e.response?.data.message?.join(' / ');
                throw new NetworkException(
                    e.response?.status,
                    `${e.message} : ${businessErrorMessage}`,
                    e.response?.data,
                );
            } else {
                throw e;
            }
        }
    };
}

const httpService = new HttpService();

export default httpService;
