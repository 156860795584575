export interface BaseListProps<T> {
    withSearch?: boolean;
    list: T[];
}

export class NameAlreadyExistsException {
    name: string;

    constructor(name: string) {
        this.name = name;
    }
}
