import { Center, Heading, Link, Stack, VStack, useMediaQuery } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { SCREENSIZE } from '../../global';
import AppStoreBadge from '../../assets/images/badge-appstore.svg';
import PlayStoreBadge from '../../assets/images/badge-playstore.svg';

const RedirectPage = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>() as { id: string };
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    return (
        <Center height="100vh">
            <VStack alignItems="center" space={8}>
                <Heading mb={10}>{t('install.title')}</Heading>
                <Stack alignItems="center" direction={isSmallScreen ? 'column' : 'row'} space={4}>
                    <Link
                        href={`https://play.google.com/store/apps/details?id=io.sparklin.app&referrer=${id}`}
                        isExternal
                    >
                        <img alt="Get it on Google Play" height="70px" src={PlayStoreBadge} />
                    </Link>
                    <Link href={`https://apps.apple.com/fr/app/sparklin/id1615738603?referrer=${id}`} isExternal>
                        <img alt="Download on the App Store" height="70px" src={AppStoreBadge} />
                    </Link>
                </Stack>
            </VStack>
        </Center>
    );
};

export default RedirectPage;
