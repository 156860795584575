import * as Sentry from '@sentry/browser';
import { NativeBaseProvider } from 'native-base';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setupInterceptors } from './store/auth/auth';
import { persistor, store } from './store/store';
import nativeBaseTheme from './theme/theme';
import version from './version';

import 'normalize.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './i18n/i18next';

const container = document.getElementById('app');

if (!container) {
    throw new Error('Missing container element');
}

const { environmentName, sentryDsn, ...props } = container.dataset;

Sentry.init({
    dsn: sentryDsn,
    environment: environmentName,
    release: version ? `sparklin-frontend@${version}` : undefined,
} as Sentry.BrowserOptions);

if (process.env.NODE_ENV === 'development') {
    // worker.start();
}
const root = createRoot(container);
root.render(
    // @hack: https://github.com/GeekyAnts/NativeBase/issues/4999
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate
            loading={null}
            onBeforeLift={() => {
                store.dispatch(setupInterceptors(store.getState().auth));
            }}
            persistor={persistor}
        >
            <Router>
                <NativeBaseProvider theme={nativeBaseTheme}>
                    <App {...props} />
                </NativeBaseProvider>
            </Router>
        </PersistGate>
    </Provider>,
    // </React>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
