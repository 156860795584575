import { Heading, IStackProps, VStack, usePropsResolution } from 'native-base';
import React, { FC } from 'react';
import styles from './BaseEntityList.module.css';

interface BaseEntityListProps extends IStackProps {
    title: string;
    children: JSX.Element | Array<JSX.Element>;
}

const BaseEntityList: FC<BaseEntityListProps> = ({ title, children, ...rest }) => {
    const resolvedProps = usePropsResolution('BaseEntityListProps', rest);

    return (
        <VStack w="full" {...resolvedProps}>
            <Heading key={2} fontWeight={600} mb={6} mt={[7, 12]} size="sm">
                {title}
            </Heading>
            <div key={3} className={styles.grid}>
                {children}
            </div>
        </VStack>
    );
};

export default BaseEntityList;
