import clsx from 'clsx';
import { t } from 'i18next';
import { Box, Row, Text, useTheme } from 'native-base';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { toggle } from '../../../store/menu/menu';
import styles from './MenuNavigationItem.module.css';

interface MenuNavigationItemProps {
    icon?: React.ReactNode;
    name: string;
    path: string;
}

const MenuNavigationItem = ({ icon, name, path }: MenuNavigationItemProps) => {
    const {
        colors: { primary },
    } = useTheme();
    const dispatch = useAppDispatch();

    return (
        <NavLink
            className={(isActive) => clsx(styles.link, { [styles.hoverableLink]: !isActive })}
            onClick={() => dispatch(toggle())}
            style={({ isActive }) => ({
                background: isActive ? `linear-gradient(180deg, ${primary[200]} 0%, ${primary[600]} 100%)` : 'inherit',
            })}
            to={path}
        >
            <Box paddingLeft={10} paddingRight={3} paddingY={3}>
                <Row alignItems="center" space={name === t('menu.Monetization') ? 3 : 4}>
                    <Box>{icon}</Box>
                    <Text fontWeight={500}>{name}</Text>
                </Row>
            </Box>
        </NavLink>
    );
};

export default MenuNavigationItem;
