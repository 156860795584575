import { Spinner, VStack } from 'native-base';
import React, { FC } from 'react';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';

interface BasePageProps {
    title: string;
    loaded: boolean;
    children?: JSX.Element | JSX.Element[];
}

const BasePage: FC<BasePageProps> = ({ title, children, loaded = true }) => (
    <VStack mx={[10, 32]} my={[10, 20]}>
        <PageLayoutHeader title={title} />
        {loaded ? children : <Spinner size="lg" />}
    </VStack>
);

export default BasePage;
