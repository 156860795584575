import { Stack, Link, Modal, Text, useMediaQuery } from 'native-base';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SCREENSIZE } from '../../global';
import GradientButton from '../Button/GradientButton/GradientButton';
import { ReactComponent as CookieIcon } from '../../assets/icons/cookie.svg';

interface CookieProps {
    open?: boolean;
    onSubmit: () => void;
}

const Cookie: FC<CookieProps> = ({ open = true, onSubmit }) => {
    const { t } = useTranslation();
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });
    const onClose = async () => {
        onSubmit();
    };

    return (
        <Modal isOpen={open} overlayVisible={false}>
            <Modal.Content margin={2} marginTop="auto" maxWidth={['80%', '70%']} paddingBottom={2}>
                <Modal.Body p={0} pb={2}>
                    <Stack alignItems="center" direction={['column', 'row']} justifyContent="space-between" space={2}>
                        {!isSmallScreen ? <CookieIcon height="60" style={{ margin: '0 20 0 0' }} width="60" /> : null}
                        <Text flexWrap="wrap" fontSize={[10, 14]} mb={[5, 0]}>
                            {t('cookie.text')}
                            <Link color="white" href="https://www.sparklin.io/cgu" ml={1} isExternal>
                                {t('cookie.link')}
                            </Link>
                            .
                        </Text>
                        <GradientButton label={t('cookie.action')} onClick={onClose} />
                    </Stack>
                </Modal.Body>
            </Modal.Content>
        </Modal>
    );
};

export default Cookie;
