import { Badge } from 'native-base';
import React, { FC } from 'react';

interface BaseBadgeProps {
    label: string;
}

const BaseBadge: FC<BaseBadgeProps> = ({ label }) => (
    <Badge bg="teal.500" height={[5, 6]}>
        {label}
    </Badge>
);

export default BaseBadge;
