import { isDefined } from '@sparklin/react.api.utils.is-defined';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SocketCard from '../../components/Cards/SocketCard';
import FormModal from '../../components/FormModal/FormModal';
import { SocketSchema } from '../../utils/search-schema';
import PageList from '../base/PageList';
import { BaseListProps } from '../base/types';
import EditSocketModalContent from './EditSocketModalContent/EditSocketModalContent';
import { EditSocketPayload, Socket } from './types';

interface SocketListProps extends BaseListProps<Socket> {
    count: number;
}

const SocketList: FC<SocketListProps> = ({ list, count, withSearch = false }) => {
    const { t } = useTranslation();
    const List = PageList<Socket>;
    const [selectedSocket, setSelectedSocket] = useState<EditSocketPayload>();

    return (
        <>
            <List
                list={list}
                renderChild={(socket) => (
                    <SocketCard
                        key={socket.id}
                        chargeStatus={socket.chargeStatus}
                        description={socket.description}
                        isConnected={socket.isConnected}
                        name={socket.name}
                        onEdit={() => setSelectedSocket(socket)}
                        readable={socket.readable}
                        siteName={socket.siteName}
                    />
                )}
                searchSchema={SocketSchema}
                title={t('sockets.socketsCount', { count })}
                withSearch={withSearch}
            />
            {selectedSocket && (
                <FormModal
                    isOpen={isDefined(selectedSocket)}
                    onClose={() => setSelectedSocket(undefined)}
                    testID="edit-user-modal"
                    title={t('sockets.edit.title')}
                >
                    <EditSocketModalContent onSubmitted={() => setSelectedSocket(undefined)} socket={selectedSocket} />
                </FormModal>
            )}
        </>
    );
};

export default SocketList;
