// eslint-disable-next-line import/prefer-default-export
export function splice(str: string, index: number, count: number, add: string) {
    // We cannot pass negative indexes directly to the 2nd slicing operation.
    let idx = index;
    if (idx < 0) {
        idx = str.length + idx;
        if (idx < 0) {
            idx = 0;
        }
    }

    return str.slice(0, idx) + (add || '') + str.slice(idx + count);
}
