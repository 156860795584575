import { useEffect, useState } from 'react';
import React, { useTranslation } from 'react-i18next';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import { Community } from '../../types';
import BasePage from '../base/BasePage';
import CommunityList from './CommunityList';

const Comunities = () => {
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);
    const [loaded, setLoaded] = useState(false);
    const [communities, setCommunities] = useState<Community[]>([]);

    const loadCommunities = () => {
        if (company) {
            setLoaded(false);
            providers.communitiesProvider.getCommunities(company.id).then((data) => {
                setCommunities(data);
                setLoaded(true);
            });
        }
    };

    useEffect(() => {
        loadCommunities();

        return () => setCommunities([]);
    }, [company]);

    return (
        <BasePage loaded={loaded} title={t('communities.title')}>
            <CommunityList
                count={communities.length}
                list={communities}
                onAddOrEdit={loadCommunities}
                withSearch
                withbutton
            />
        </BasePage>
    );
};

export default Comunities;
