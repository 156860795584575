import { Modal, Text } from 'native-base';
import React, { useEffect, useState } from 'react';

interface FormModalProps {
    isOpen: boolean;
    onClose: (isRefreshNeeded: boolean) => void;
    title: string;
    children: React.ReactNode;
    testID: string;
}

const FormModal = ({ isOpen, onClose, title, children, testID }: FormModalProps) => {
    const [key, setKey] = useState<number | undefined>(undefined);
    const [offset, setOffset] = useState(0);

    /**
     * Allows to generate a new key when we open the modal, thus the form is recreated.
     */
    useEffect(() => {
        if (isOpen) {
            setKey(Date.now());
        }
        const onScroll = () => setOffset(window.scrollY);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });

        return () => window.removeEventListener('scroll', onScroll);
    }, [isOpen]);

    return (
        <Modal h="100vh" isOpen={isOpen} mt={`${offset} px`} onClose={onClose} position="fixed" px={2} testID={testID}>
            <Modal.Content w="full">
                <Modal.CloseButton position="absolute" testID="close-button-modal" top={5} />
                <Modal.Header bg="primary" w={['90%', null]}>
                    <Text fontSize={[20, 30]} bold>
                        {title}
                    </Text>
                </Modal.Header>
                <React.Fragment key={key}>{isOpen && children}</React.Fragment>
            </Modal.Content>
        </Modal>
    );
};

export default FormModal;
