import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, VStack, WarningOutlineIcon } from 'native-base';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import GradientButton from '../../components/Button/GradientButton/GradientButton';
import CustomPhoneInput from '../../components/CustomPhoneInput/CustomPhoneInput';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { isLoadingSelect, sendPhoneNumberThunk } from '../../store/login/login';

/**
 * First part of the Login form, to ask for the phone number.
 */
const PhoneNumberPart = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(isLoadingSelect);

    const phoneNumberSchema = yup.object({
        phoneNumber: yup
            .string()
            .required(t('common.error.phoneNumber.required'))
            .test(
                'isPhoneNumberValid',
                t('common.error.phoneNumber.format'),
                (value) => value !== undefined && isPossiblePhoneNumber(value),
            ),
    });

    const phoneNumberForm = useForm<yup.InferType<typeof phoneNumberSchema>>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(phoneNumberSchema),
    });

    const onSubmitPhoneNumberPressed = phoneNumberForm.handleSubmit((data) => {
        dispatch(sendPhoneNumberThunk(data.phoneNumber));
    });

    return (
        <form onSubmit={onSubmitPhoneNumberPressed}>
            <VStack>
                <FormControl isInvalid={phoneNumberForm.formState.errors.phoneNumber !== undefined} mb={6}>
                    <FormControl.Label>{t('login.enterPhoneNumber')}</FormControl.Label>
                    <Controller
                        control={phoneNumberForm.control}
                        name="phoneNumber"
                        render={({ field }) => (
                            <CustomPhoneInput
                                onChange={field.onChange}
                                testID="phone-number-input"
                                value={field.value}
                            />
                        )}
                    />
                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                        {phoneNumberForm.formState.errors.phoneNumber?.message}
                    </FormControl.ErrorMessage>
                </FormControl>
                <GradientButton
                    fontSize={[12, null]}
                    isLoading={isLoading}
                    label={t('login.askForCode')}
                    type="submit"
                />
            </VStack>
        </form>
    );
};

export default PhoneNumberPart;
