import { Badge, HStack, Text, VStack } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SocketChargeStatus } from '../../pages/Sockets/types';
import getChargeStatusLabel from '../../pages/Sockets/utils';
import BaseCard from './BaseCard';
import socket from '../../assets/images/socket.svg';

interface SocketCardProps {
    name: string;
    isPressable?: boolean;
    siteName?: string;
    description?: string;
    chargeStatus: SocketChargeStatus;
    isConnected: boolean;
    readable: string;
    onTouch?: VoidFunction;
    onEdit?: VoidFunction;
}

const getChargeStatusColor = (chargeStatus: SocketChargeStatus, isConnected: boolean) => {
    if (!isConnected) {
        return 'error.600';
    }
    switch (chargeStatus) {
        case SocketChargeStatus.CHARGING:
            return 'primary.600';
        case SocketChargeStatus.NOT_CHARGING:
        default:
            return 'teal.500';
    }
};

const SocketCard = ({
    name,
    siteName,
    chargeStatus,
    isConnected,
    isPressable,
    description,
    readable,
    onTouch,
    onEdit,
}: SocketCardProps) => {
    const { t } = useTranslation();

    return (
        <BaseCard
            badge={
                <HStack alignItems="center" justifyContent="space-between" w="full">
                    <Text color="gray.400" flexWrap="nowrap" fontSize="xs" fontStyle="italic">
                        #{readable}
                    </Text>
                    <Badge bg={getChargeStatusColor(chargeStatus, isConnected)}>
                        {getChargeStatusLabel(t, chargeStatus, isConnected)}
                    </Badge>
                </HStack>
            }
            imageSrc={socket}
            isPressable={isPressable}
            onEdit={siteName ? onEdit : undefined}
            onTouch={onTouch}
            title={name}
        >
            <VStack>
                <Text color="gray.400" fontSize="0.7em">
                    {description}
                </Text>
                <Text fontSize="xs">{siteName}</Text>
                {/* <SiteBadge site={} */}
            </VStack>
        </BaseCard>
    );
};

export default SocketCard;
