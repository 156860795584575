import { EditSocketPayload, Socket, SocketChargeStatus } from '../../pages/Sockets/types';
import BaseProvider from '../base.provider';
import { GetSocketWSOutput, GetSocketsWSOutput } from './types';

export default class SocketProvider extends BaseProvider {
    getSockets = async (companyId: CompanyId): Promise<Socket[]> =>
        (await this.http.get<GetSocketsWSOutput>(`/api/v1/companies/${companyId}/sockets`)).sockets.map((socket) => ({
            id: socket.id,
            name: socket.name,
            siteName: socket.siteName,
            description: socket.description,
            chargeStatus: socket.chargeStatus as SocketChargeStatus,
            isConnected: socket.isConnected,
            readable: socket.readable,
        }));

    getSocket = async (socketId: string): Promise<Socket> => {
        const socket = await this.http.get<GetSocketWSOutput>(`/api/v1/sockets/active/${socketId}`);

        return {
            id: socket.id,
            name: socket.name,
            siteName: socket.siteName,
            chargeStatus: socket.chargeStatus as SocketChargeStatus,
            isConnected: socket.isConnected,
            readable: socket.readable,
        };
    };

    getPublicSocket = async (
        readable: string,
    ): Promise<Pick<GetSocketWSOutput, 'id' | 'name' | 'isConnected' | 'activated'>> => {
        const socket = await this.http.get<GetSocketWSOutput>(`/api/v1/sockets/readable/${readable}`);

        return {
            id: socket.id,
            name: socket.name,
            isConnected: socket.isConnected,
            activated: socket.activated,
        };
    };

    editSocket = async (editSocketPayload: EditSocketPayload): Promise<any> =>
        this.http.patch(`/api/v1/sockets/${editSocketPayload.id}`, {
            name: editSocketPayload.name,
            description: editSocketPayload.description,
        });
}
