import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, Button, Checkbox, FormControl, Input, Modal, Text, WarningOutlineIcon } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import GradientButton from '../../../components/Button/GradientButton/GradientButton';
import ChipSelector from '../../../components/ChipSelector/ChipSelector';
import CustomPhoneInput from '../../../components/CustomPhoneInput/CustomPhoneInput';
import handleError from '../../../components/ErrorToast/ErrorToast';
import { invokeSuccessToast } from '../../../components/Toast/Toast';
import providers from '../../../providers';
import { companySelect } from '../../../store/auth/auth';
import { useAppSelector } from '../../../store/hooks';
import { Community } from '../../../types';
import { UserDetails } from '../../UserDetails/types';
import { ExistingUser, UserAlreadyExistsException } from './types';
import styles from './AddUserModalContent.module.css';

/**
 * Form used to create or edit a new user
 */
const AddOrEditUserModalContent = ({
    editingUser,
    onSubmitted,
}: {
    editingUser?: UserDetails; // The user we want to edit
    onSubmitted: VoidFunction;
}) => {
    const { t } = useTranslation();

    const [communities, setCommunities] = useState<Community[] | undefined>(undefined);
    const company = useAppSelector(companySelect);
    const [isRequestPending, setIsRequestPending] = useState<boolean>(false);
    const [existingUser, setExistingUser] = useState<ExistingUser | undefined>(undefined);

    const mapUserDetailsToForm = (details: UserDetails): yup.InferType<typeof formSchema> => ({
        firstName: details.firstName,
        lastName: details.lastName,
        phoneNumber: details.phoneNumber,
        email: details.email,
        isManager: details.isManager,
        isClient: details.communities.length > 0,
        communities: details.communities.map((community) => community.id),
    });

    const formSchema = yup.object({
        lastName: yup.string().required(t('users.add.error.lastName.required')),
        firstName: yup.string().required(t('users.add.error.firstName.required')),
        phoneNumber: yup
            .string()
            .required(t('common.error.phoneNumber.required'))
            .test(
                'isPhoneNumberValid',
                t('common.error.phoneNumber.format'),
                (value) => value !== undefined && isPossiblePhoneNumber(value),
            ),
        email: yup.string().required(t('common.error.email.required')).email(t('common.error.email.format')),
        isManager: yup.boolean().default(false),
        isClient: yup
            .boolean()
            .default(false)
            .when('isManager', {
                is: (isManager: boolean) => !isManager,
                then: (schema) => schema.oneOf([true], t('users.add.roles.oneMustBeChecked')),
            }),
        communities: yup
            .array()
            .of(yup.number().required())
            .when('isClient', {
                is: true,
                then: (schema) =>
                    schema
                        .required(t('users.add.communities.oneMustBeChecked'))
                        .min(1, t('users.add.communities.oneMustBeChecked')),
                otherwise: (schema) => schema.optional(),
            }),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        watch,
        getValues,
    } = useForm<yup.InferType<typeof formSchema>>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(formSchema),
        defaultValues: editingUser
            ? mapUserDetailsToForm(editingUser)
            : {
                  firstName: '',
                  lastName: '',
                  phoneNumber: '',
                  email: '',
                  isManager: false,
                  isClient: false,
              },
    });

    /**
     * Effect to get the communities displayed in the form (when the user selects the role "client")
     */
    useEffect(() => {
        if (!communities && (getValues('isClient') || getValues('isManager'))) {
            if (company) {
                if (getValues('isClient')) {
                    providers.communitiesProvider.getCommunities(company.id).then(setCommunities).catch(handleError);
                }
            } else {
                setCommunities([]);
            }
        }
    }, [watch('isClient'), watch('isManager')]);

    /**
     * Displays a toast about the success of form submission.
     * @param userName the user name displayed in the toast.
     * @param editionForm says if the form is displayed to edit instead of creating
     */
    const displaySuccessToast = (userName: string, editionForm: boolean = false) =>
        invokeSuccessToast(
            t('common.success.title'),
            editionForm
                ? t('userDetails.edit.success.description', { userName })
                : t('users.add.success.description', { userName }),
        );

    const onValidate = handleSubmit((data) => {
        if (company) {
            setIsRequestPending(true);
            if (editingUser) {
                // We are editing a user
                providers.usersProvider
                    .editUser(company.id, {
                        id: editingUser.id,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phoneNumber: data.phoneNumber,
                        email: data.email,
                        isManager: data.isManager,
                        communities: data.isClient ? data.communities : undefined,
                    })
                    .then(() => {
                        displaySuccessToast(`${data.firstName} ${data.lastName}`, true);
                        onSubmitted();
                    })
                    .catch(handleError)
                    .finally(() => setIsRequestPending(false));
            } else if (!existingUser) {
                // We are creating a user and no already-existing user has been spotted
                providers.usersProvider
                    .addUser(company.id, {
                        communities: data.isClient ? data.communities : undefined,
                        email: data.email,
                        firstName: data.firstName,
                        isManager: data.isManager,
                        lastName: data.lastName,
                        phoneNumber: data.phoneNumber,
                    })
                    .then(() => {
                        displaySuccessToast(`${data.firstName} ${data.lastName}`);
                        onSubmitted();
                    })
                    .catch((err) => {
                        if (err instanceof UserAlreadyExistsException) {
                            setExistingUser({
                                id: err.existingUser.id,
                                firstName: err.existingUser.firstName,
                                lastName: err.existingUser.lastName,
                                phoneNumber: err.existingUser.phoneNumber,
                            });
                        } else {
                            handleError(err);
                        }
                    })
                    .finally(() => {
                        setIsRequestPending(false);
                    });
            } else {
                // We are creating a user, and we know that another user exists with the same phone number
                providers.usersProvider
                    .addUserRoles(company.id, existingUser.id, {
                        communities: data.isClient ? data.communities : undefined,
                        isManager: data.isManager,
                    })
                    .then(() => {
                        displaySuccessToast(`${existingUser.firstName} ${existingUser.lastName}`);
                        onSubmitted();
                    })
                    .catch(handleError)
                    .finally(() => {
                        setIsRequestPending(false);
                    });
            }
        }
    });

    return (
        <>
            <Modal.Body>
                {existingUser ? (
                    <Text>
                        {t('users.add.userAlreadyExists', {
                            firstName: existingUser.firstName,
                            lastName: existingUser.lastName,
                        })}
                    </Text>
                ) : (
                    <>
                        <div className={styles.grid}>
                            <FormControl isInvalid={errors.firstName !== undefined}>
                                <FormControl.Label>{t('users.add.firstName.label')}</FormControl.Label>
                                <Controller
                                    control={control}
                                    name="firstName"
                                    render={({ field: { onBlur, onChange, ref, value } }) => (
                                        <Input
                                            ref={ref}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            placeholder={t('users.add.firstName.placeholder')}
                                            value={value}
                                            variant="rounded"
                                        />
                                    )}
                                />
                                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                    {errors.firstName?.message}
                                </FormControl.ErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.lastName !== undefined}>
                                <FormControl.Label>{t('users.add.lastName.label')}</FormControl.Label>
                                <Controller
                                    control={control}
                                    name="lastName"
                                    render={({ field: { onBlur, onChange, ref, value } }) => (
                                        <Input
                                            ref={ref}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            placeholder={t('users.add.lastName.placeholder')}
                                            value={value}
                                            variant="rounded"
                                        />
                                    )}
                                />

                                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                    {errors.lastName?.message}
                                </FormControl.ErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.phoneNumber !== undefined}>
                                <FormControl.Label>{t('users.add.phoneNumber.label')}</FormControl.Label>
                                <Controller
                                    control={control}
                                    name="phoneNumber"
                                    render={({ field }) => (
                                        <CustomPhoneInput
                                            onChange={field.onChange}
                                            placeholder={t('users.add.phoneNumber.placeholder')}
                                            value={field.value}
                                        />
                                    )}
                                />

                                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                    {errors.phoneNumber?.message}
                                </FormControl.ErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={errors.email !== undefined}>
                                <FormControl.Label>{t('users.add.email.label')}</FormControl.Label>
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field: { onBlur, onChange, ref, value } }) => (
                                        <Input
                                            ref={ref}
                                            keyboardType="email-address"
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            placeholder={t('users.add.email.placeholder')}
                                            textContentType="emailAddress"
                                            value={value}
                                            variant="rounded"
                                        />
                                    )}
                                />

                                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                    {errors.email?.message}
                                </FormControl.ErrorMessage>
                            </FormControl>
                        </div>
                        <Box height={4} />
                        <FormControl isInvalid={errors.isClient !== undefined}>
                            <Controller
                                control={control}
                                name="isManager"
                                render={({ field }) => (
                                    <Checkbox
                                        isChecked={field.value}
                                        my="1"
                                        onChange={field.onChange}
                                        value="isManager"
                                    >
                                        {t('users.add.roles.manager', { companyName: company?.name })}
                                    </Checkbox>
                                )}
                            />
                            <Controller
                                control={control}
                                name="isClient"
                                render={({ field }) => (
                                    <Checkbox isChecked={field.value} my="1" onChange={field.onChange} value="isClient">
                                        {t('users.add.roles.client')}
                                    </Checkbox>
                                )}
                            />

                            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                {errors.isClient?.message}
                            </FormControl.ErrorMessage>
                        </FormControl>
                        {watch('isClient') ? (
                            <FormControl isInvalid={errors.communities !== undefined}>
                                <Controller
                                    control={control}
                                    name="communities"
                                    render={({ field }) => (
                                        <>
                                            <FormControl.Label>
                                                {t('users.add.communities.label', {
                                                    count: (field.value ?? []).length,
                                                })}
                                            </FormControl.Label>
                                            <ChipSelector
                                                chipList={communities}
                                                onChange={field.onChange}
                                                selectedChips={field.value || []}
                                            />
                                        </>
                                    )}
                                />
                                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                    {(errors.communities as unknown as FieldError)?.message}
                                </FormControl.ErrorMessage>
                            </FormControl>
                        ) : null}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <GradientButton
                    fontSize="sm"
                    isLoading={isRequestPending}
                    label={editingUser ? t('userDetails.edit.validate') : t('users.add.validate')}
                    onClick={onValidate}
                />
                {existingUser ? (
                    <Button
                        disabled={isRequestPending}
                        fontSize="sm"
                        marginLeft={3}
                        onPress={() => {
                            setExistingUser(undefined);
                        }}
                        variant="ghost"
                    >
                        {t('common.cancel')}
                    </Button>
                ) : null}
            </Modal.Footer>
        </>
    );
};

export default AddOrEditUserModalContent;
