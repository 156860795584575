import React, { useEffect } from 'react';
import useScript from '../../../hooks/useScript';
import nativeBaseTheme from '../../../theme/theme';

interface ZendeskWidgetProps {
    language?: string;
    theme?: string;
    prefill?: {
        name: string;
        email: string;
    };
}

const ZendeskWidget: React.FC<ZendeskWidgetProps> = ({
    language = process.env.REACT_APP_ZENDESK_CONTACT_FORM_DEFAULT_LANGUAGE,
    theme = nativeBaseTheme.colors.primary[700],
    prefill,
}) => {
    const { loaded, error } = useScript(
        process.env.REACT_APP_ZENDESK_SCRIPT_URL ?? '',
        process.env.REACT_APP_ZENDESK_TAG_ID,
    );

    useEffect(() => {
        if (!error && loaded) {
            // Initialize widget
            window.zE('webWidget', 'setLocale', language);
            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    color: {
                        theme,
                        launcherText: '#fff',
                        header: theme,
                    },
                },
            });

            if (prefill) {
                window.zE('webWidget', 'prefill', {
                    name: {
                        value: prefill.name,
                        readOnly: true,
                    },
                    email: {
                        value: prefill.email,
                        readOnly: true,
                    },
                });
            }

            window.zE('webWidget', 'show');

            return () => {
                window.zE('webWidget', 'hide');
            };
        }

        return undefined;
    }, [loaded, error, language, prefill]);

    return null;
};

export default ZendeskWidget;
