import { TFunction } from 'i18next';
import { User } from './types';

const getStatusLabel = (t: TFunction, st: User['status']) => {
    switch (st) {
        case 'ACTIVE':
        default:
            return t('users.card.status.ACTIVE');
    }
};

// export const

export default getStatusLabel;
