import { HStack, Text } from 'native-base';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RC_PUBLIC_COMMUNITY } from '../../global';
import useRemoteConfig from '../../hooks/use-remote-config';
import { CommunityUsageMode } from '../../types';
import { getUsageModeLabel } from '../../utils/get-localized-label';
import CardIcon from '../Icons/CardIcon';
import LockIcon from '../Icons/LockIcon';
import BaseCard from './BaseCard';
import communityImg from '../../assets/images/community.svg';

interface CommunityCardProps {
    name: string;
    paymentEnabled?: boolean;
    usageMode: CommunityUsageMode;
    isDefault?: boolean;
    isPublic?: boolean;
    onTouch: VoidFunction;
}

const CommunityCard: FC<CommunityCardProps> = ({
    name,
    onTouch,
    paymentEnabled = true,
    usageMode,
    isDefault = false,
    isPublic = false,
}) => {
    const { t } = useTranslation();
    const featureEnable = useRemoteConfig(RC_PUBLIC_COMMUNITY).asBoolean();

    return (
        <BaseCard
            badge={isDefault ? t('communities.card.default') : undefined}
            icon={
                <HStack alignItems="center" space={2}>
                    {isPublic && featureEnable ? <LockIcon lock={!isPublic} /> : null}
                    {paymentEnabled ? <CardIcon paymentEnabled={paymentEnabled} /> : null}
                </HStack>
            }
            imageSrc={communityImg}
            onTouch={onTouch}
            title={name}
            isPressable
        >
            <Text fontSize="xs">{getUsageModeLabel(t, usageMode)}</Text>
        </BaseCard>
    );
};

export default CommunityCard;
