import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import BasePage from '../base/BasePage';
import { User } from './types';
import UserList from './UserList';

const UsersPage = () => {
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);
    const [loaded, setLoaded] = useState(false);
    const [users, setUsers] = useState<User[]>([]);

    const loadUsers = () => {
        if (company) {
            setLoaded(false);
            providers.usersProvider
                .getUsers(company.id)
                .then((data) => {
                    setUsers(data);
                })
                .finally(() => setLoaded(true));
        } else {
            setLoaded(true);
        }
    };

    useEffect(() => {
        loadUsers();

        return () => setUsers([]);
    }, [company]);

    return (
        <BasePage loaded={loaded} title={t('users.title')}>
            <UserList count={users.length} list={users} onAddOrEdit={loadUsers} withSearch withbutton />
        </BasePage>
    );
};

export default UsersPage;
