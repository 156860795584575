import { Community } from '../../../types';
import { SiteType } from '../types';

export type EditSiteCommunity = Pick<Community, 'id' | 'name'>;

export interface EditSitePayload {
    id: SiteId;
    communities: CommunityId[] | undefined;
    name: string;
    type: SiteType;
}

export class SiteNameAlreadyExistsException {
    name: string;

    constructor(name: string) {
        this.name = name;
    }
}
