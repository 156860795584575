import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company } from '../../pages/Users/types';
import providers from '../../providers';
import { LoginErrors } from '../login/types';
// cf. https://redux.js.org/usage/usage-with-typescript#define-slice-state-and-action-types ⬇️
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';
import { AuthState, GetCompanyPayload, LoginInfo } from './types';

export const authSliceName = 'auth';
const initialState: AuthState = {};

export const disconnectThunk = createAsyncThunk('auth/disconnect', () => {
    providers.httpService.removeInterceptors();
});

export const setupInterceptors = createAsyncThunk('auth/setupInterceptors', (authState: AuthState, { dispatch }) => {
    // At startup, if token is available, we set up the interceptors to inject tokens in requests
    if (authState.token !== undefined) {
        providers.httpService.setInterceptors(authState.token, () => dispatch(disconnectThunk()));
    }
});

export const getCompany = createAsyncThunk<GetCompanyPayload, void>(
    'auth/getCompany',
    async (_, { rejectWithValue, getState }) => {
        const companies = await providers.companiesProvider.getCompanies();
        if (companies.length === 0) {
            return rejectWithValue(LoginErrors.NO_COMPANY);
        }

        const previouslyChosenCompany = (getState() as RootState).auth.company;
        if (
            previouslyChosenCompany !== undefined &&
            companies.findIndex((company) => company.id === previouslyChosenCompany.id) !== -1
        ) {
            // If the previously chosen company is still available in the companies array, we choose this one.
            // If not, we choose the first one of the array
            return {
                selectedCompany: previouslyChosenCompany,
                companies: companies.map((companyWs) => ({
                    id: companyWs.id,
                    name: companyWs.name,
                })),
            };
        }

        return {
            selectedCompany: {
                id: companies[0].id,
                name: companies[0].name,
            },
            companies: companies.map((companyWs) => ({
                id: companyWs.id,
                name: companyWs.name,
            })),
        };
    },
);

const authSlice = createSlice({
    name: authSliceName,
    initialState,
    reducers: {
        connect: (state, { payload }: PayloadAction<LoginInfo>) => ({
            ...state,
            token: payload.token,
            userId: payload.userId,
            roles: payload.roles,
        }),
        changeCompany: (state, { payload }: PayloadAction<Company>) => ({
            ...state,
            company: payload,
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(disconnectThunk.fulfilled, () => initialState);
        builder.addCase(getCompany.fulfilled, (state, action) => ({
            ...state,
            company: action.payload.selectedCompany,
        }));
    },
});

export const isAuthedSelect = (state: RootState) => state.auth.token;
export const companySelect = (state: RootState) => state.auth.company;

export const { connect, changeCompany } = authSlice.actions;
export default authSlice.reducer;
