import { TFunction } from 'i18next';
import { Site, SiteType } from './types';

const getSiteTypeLabel = (t: TFunction, st: Site['type']) => {
    switch (st) {
        case SiteType.PRIVATE:
            return t('sites.card.siteType.PRIVATE');
        case SiteType.SHARED:
        default:
            return t('sites.card.siteType.SHARED');
    }
};

export default getSiteTypeLabel;
