import { BeneficiaryAccountLink, Configuration } from '../../pages/Monetization/types';
import { Company } from '../../pages/Users/types';
import BaseProvider from '../base.provider';
import {
    GetBeneficiaryAccountLinkWSOutput,
    GetCompaniesWSOutput,
    GetConfigurationWSOutput,
    PutConfigurationWSInput,
    PutConfigurationWSOutput,
} from './types';

export default class CompanyProvider extends BaseProvider {
    getCompanies = async (): Promise<Company[]> =>
        (await this.http.get<GetCompaniesWSOutput>('/api/v1/companies')).companies;

    getConfiguration = async (companyId: CompanyId): Promise<Configuration> => {
        const companyConfigWs = await this.http.get<GetConfigurationWSOutput>(
            `/api/v1/companies/${companyId}/configuration`,
        );

        return {
            costPerKWh: companyConfigWs.costPerKWh,
            paymentActivated: companyConfigWs.paymentActivated,
            accountInformations: companyConfigWs.accountInformations,
            currency: companyConfigWs.currency,
            maxUCAmount: companyConfigWs.maxUCAmount,
        };
    };

    putConfiguration = async (companyId: CompanyId, configuration: PutConfigurationWSInput): Promise<Configuration> => {
        const companyConfigWs = await this.http.put<PutConfigurationWSOutput, PutConfigurationWSInput>(
            `/api/v1/companies/${companyId}/configuration`,
            {
                costPerKWh: configuration.costPerKWh,
                paymentActivated: configuration.paymentActivated,
            },
        );

        return {
            costPerKWh: companyConfigWs.costPerKWh,
            paymentActivated: companyConfigWs.paymentActivated,
            currency: companyConfigWs.currency,
            maxUCAmount: companyConfigWs.maxUCAmount,
        };
    };

    getBeneficiaryAccountLink = async (companyId: CompanyId): Promise<BeneficiaryAccountLink> => {
        const beneficiaryAccountLink = await this.http.get<GetBeneficiaryAccountLinkWSOutput>(
            `/api/v1/companies/${companyId}/beneficiary-account-link`,
        );

        return {
            url: beneficiaryAccountLink.url,
        };
    };
}
