import { Flex, Center, HamburgerIcon, Box } from 'native-base';
import React, { FC, ReactNode } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { toggle } from '../../store/menu/menu';
import ButtonIcon from '../Button/ButtonIcon';

interface HeaderBarProps {
    title?: ReactNode;
}

const HeaderBar: FC<HeaderBarProps> = ({ title }) => {
    const dispatch = useAppDispatch();

    const hamburgerIconPress = () => {
        dispatch(toggle());
        window.scrollTo(0, 0);
    };

    return (
        <Flex
            bg="#1A1D2D"
            borderBottomColor="rgba(255, 255, 255, 0.5)"
            borderBottomWidth="1px"
            direction="row"
            left="0"
            position="fixed"
            top="0"
            width="100%"
        >
            <ButtonIcon icon={<HamburgerIcon size={6} />} onPress={hamburgerIconPress} variant="unstyled" />
            <Center
                _text={{ color: 'gray.50', fontWeight: 600, fontSize: 'lg', fontFamily: 'Poppins-SemiBold' }}
                flex={1}
            >
                {title}
            </Center>
            <Box width="10%" />
        </Flex>
    );
};

export default HeaderBar;
