import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';
import { MenuState } from './type';

const initialState: MenuState = { show: false };

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        toggle: (state) => ({ ...state, show: !state.show }),
    },
});

export const menuState = (state: RootState) => state.menu;

export const { toggle } = menuSlice.actions;

export default menuSlice.reducer;
