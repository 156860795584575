import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Button,
    Center,
    FormControl,
    HStack,
    Heading,
    Modal,
    Spinner,
    Text,
    VStack,
    WarningOutlineIcon,
    useMediaQuery,
} from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import ReadableInput from '../../components/Input/ReadableInput';
import { READABLE_SIZE, SCREENSIZE } from '../../global';
import providers from '../../providers';
import { ReactComponent as SocketFront } from '../../assets/images/spark1-front.svg';
import { ReactComponent as SocketSide } from '../../assets/images/spark1-side.svg';

const ReadablePage = () => {
    const { t } = useTranslation();
    const { code } = useParams<{ code?: string }>() as { code?: string };
    const hasMounted = useRef(false);
    const navigate = useNavigate();
    const [showHelp, setShowHelp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });
    const codeSchema = yup.object({
        code: yup.string().required(t('setup.error.code.required')).length(11, t('setup.error.code.incorrectLength')),
    });

    const {
        handleSubmit: handleSubmitOtp,
        formState: { errors },
        control: controlOtp,
        setError,
        reset,
        getValues,
    } = useForm<yup.InferType<typeof codeSchema>>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(codeSchema),
        defaultValues: {
            code: code?.toUpperCase(),
        },
    });

    const fetchSocket = async (validCode: string) => {
        try {
            setLoading(true);
            const { id, activated } = await providers.socketsProvider.getPublicSocket(validCode);
            if (activated) {
                navigate(`/go-to/${id}`);
            } else {
                navigate(`/setup/${id}`);
            }
        } catch (error) {
            if ((error as Response).status === 404) {
                reset({ code: '' }, { keepErrors: true });
                setError('code', { type: '404', message: t('setup.error.code.notFound') });
            }
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = handleSubmitOtp(async (data) => {
        await fetchSocket(data.code);
    });

    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            if (getValues('code')?.length === READABLE_SIZE) {
                onSubmit();
            }
        }
    }, [getValues]);

    return (
        <Center height="80vh">
            <VStack alignItems="center" space={10}>
                <Box mb={6}>
                    <SocketFront viewBox="0 0 185 115" width={isSmallScreen ? 200 : 450} />
                </Box>
                {!loading ? (
                    <FormControl isInvalid={errors.code !== undefined} mb={6}>
                        <HStack alignItems="center" mb={1}>
                            <FormControl.Label style={{ marginTop: 4, marginBottom: 14 }}>
                                <Heading>{t('setup.enterReadable')}</Heading>
                            </FormControl.Label>
                        </HStack>
                        <Controller
                            control={controlOtp}
                            name="code"
                            render={({ field }) => (
                                <ReadableInput
                                    numInputs={READABLE_SIZE}
                                    onChange={field.onChange}
                                    onSubmit={onSubmit}
                                    value={field.value}
                                />
                            )}
                        />
                        <FormControl.HelperText mt={4}>
                            <Button
                                onPress={() => setShowHelp(true)}
                                px={0}
                                style={{
                                    // @ts-ignore
                                    cursor: 'help',
                                }}
                                variant="link"
                            >
                                {t('setup.findReadable')}
                            </Button>
                        </FormControl.HelperText>
                        <FormControl.ErrorMessage
                            backgroundColor="warning.100"
                            borderRadius={2}
                            leftIcon={<WarningOutlineIcon mr={2} size="md" />}
                            mt={4}
                            mx={4}
                            p={2}
                        >
                            <Text fontSize="md">{errors.code?.message}</Text>
                        </FormControl.ErrorMessage>
                    </FormControl>
                ) : (
                    <Spinner size="lg" />
                )}
            </VStack>
            <Modal isOpen={showHelp} onClose={() => setShowHelp(false)}>
                <Modal.Content px={2} py={2}>
                    <Modal.CloseButton testID="close-button-modal" />
                    <Modal.Header bg="primary">{t('common.readable')}</Modal.Header>
                    <Modal.Body>
                        <Text mb={[5, 50]}>{t('setup.readablePosition')}</Text>
                        <Center>
                            <HStack alignItems="center" justifyContent="center" space={8}>
                                <SocketFront viewBox="125 8 50 55" width={340} />
                                {!isSmallScreen && <SocketSide viewBox="0 0 90 115" width={250} />}
                            </HStack>
                        </Center>
                    </Modal.Body>
                </Modal.Content>
            </Modal>
        </Center>
    );
};

export default ReadablePage;
