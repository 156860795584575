import { Button, IButtonProps, usePropsResolution } from 'native-base';
import React, { FC } from 'react';

export interface ButtonIconProps extends IButtonProps {
    icon: JSX.Element;
    size?: string;
    variant?: IButtonProps['variant'];
    onPress?: VoidFunction;
}

const ButtonIcon: FC<ButtonIconProps> = ({ icon, onPress, variant, size = '43px', ...rest }) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const resolvedProps = usePropsResolution('GradientButton', rest);

    return (
        <Button {...resolvedProps} h={size} onPress={onPress} variant={variant} w={size}>
            {icon}
        </Button>
    );
};

export default ButtonIcon;
