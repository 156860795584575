import { UserDetails } from '../../pages/UserDetails/types';
import {
    AddUserPayload,
    AddUserRolesPayload,
    EditUserPayload,
    ExistingUser,
    UserAlreadyExistsException,
} from '../../pages/Users/AddUserModalContent/types';
import { DeleteUserPayload } from '../../pages/Users/DeleteUserModalContent/types';
import { User } from '../../pages/Users/types';
import BaseProvider from '../base.provider';
import { isNetworkException, NetworkException } from '../types';
import {
    AddUserRolesWSInput,
    AddUserWSInput,
    EditUserWSInput,
    EditUserWSOutput,
    GetUsersWSOutput,
    GetUserWSOutput,
} from './types';

export default class UserProvider extends BaseProvider {
    addUser = async (companyId: CompanyId, addUserPayload: AddUserPayload) =>
        this.http.post<void, AddUserWSInput>(`/api/v1/companies/${companyId}/users`, addUserPayload).catch((err) => {
            if (isNetworkException(err) && err.status === 409) {
                const exception = err as NetworkException<ExistingUser>;

                throw new UserAlreadyExistsException({
                    id: exception.data.id,
                    firstName: exception.data.firstName,
                    lastName: exception.data.lastName,
                    phoneNumber: exception.data.phoneNumber,
                });
            }

            throw err;
        });

    addUserRoles = async (companyId: CompanyId, userId: string, addUserPayload: AddUserRolesPayload) =>
        this.http.post<void, AddUserRolesWSInput>(
            `/api/v1/companies/${companyId}/users/${userId}/add-roles`,
            addUserPayload,
        );

    deleteUser = async (companyId: CompanyId, deleteUserPayload: DeleteUserPayload) =>
        this.http.delete<void>(`/api/v1/companies/${companyId}/users/${deleteUserPayload.id}`);

    editUser = async (companyId: CompanyId, editUserPayload: EditUserPayload) =>
        this.http.put<EditUserWSOutput, EditUserWSInput>(
            `/api/v1/companies/${companyId}/users/${editUserPayload.id}`,
            editUserPayload,
        );

    getUsers = async (companyId: CompanyId): Promise<User[]> =>
        (await this.http.get<GetUsersWSOutput>(`/api/v1/companies/${companyId}/users`)).users.map((user) => ({
            id: user.id,
            communities: user.communities.map((comm) => comm.name),
            phoneNumber: user.phoneNumber,
            status: user.status,
            lastName: user.lastName,
            firstName: user.firstName,
            chargesCount: user.chargesCount,
            isManager: user.isManager,
        }));

    getUserDetails = async (companyId: CompanyId, userId: string): Promise<UserDetails> =>
        this.http.get<GetUserWSOutput>(`/api/v1/companies/${companyId}/users/${userId}`);
}
