import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../pages/Users/types';
import getStatusLabel from '../../pages/Users/utils';
import BaseBadge from './BaseBadge';

interface UserBadgeProps {
    status: User['status'];
}

const UserBadge: FC<UserBadgeProps> = ({ status }) => {
    const { t } = useTranslation();

    return <BaseBadge label={getStatusLabel(t, status)} />;
};

export default UserBadge;
