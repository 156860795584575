import { Button, ChevronLeftIcon, ChevronRightIcon, HStack, Text } from 'native-base';
import React, { FC } from 'react';

interface YearSelectorProps {
    setYear: (year: number) => void;
    year: number;
}

const YearSelector: FC<YearSelectorProps> = ({ setYear, year }) => (
    <HStack alignItems="center" justifyContent="space-around" py={2} w="full">
        <Button disabled={year <= 2022} onPress={() => setYear(year - 1)} size={12} variant="ghost">
            <ChevronLeftIcon color={year <= 2022 ? 'gray.500' : 'white'} />
        </Button>
        <Text fontSize="lg" fontWeight="semibold">
            {year}
        </Text>
        <Button
            disabled={year === new Date().getFullYear()}
            onPress={() => setYear(year + 1)}
            size={12}
            variant="ghost"
        >
            <ChevronRightIcon color={year === new Date().getFullYear() ? 'gray.500' : 'white'} />
        </Button>
    </HStack>
);

export default YearSelector;
