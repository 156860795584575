import { isDefined } from '@sparklin/react.api.utils.is-defined';
import { Box, Heading, HStack, Spinner, VStack, Image, Container, useMediaQuery } from 'native-base';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import { SCREENSIZE } from '../../global';

interface BaseDetailsProps {
    backUrl: string;
    imageSrc: string;
    isLoading: boolean;
    hideHeader?: boolean;
    title?: string | JSX.Element;
    error?: Error;
    headerAction?: React.ReactNode;
    headerChildren?: React.ReactNode;
    children?: React.ReactNode;
    testID?: string;
}

const BaseDetails: FC<BaseDetailsProps> = ({
    backUrl,
    imageSrc,
    error,
    isLoading = true,
    hideHeader = false,
    title,
    headerAction,
    headerChildren,
    children,
    testID,
}) => {
    const { t } = useTranslation();
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    return !hideHeader ? (
        <VStack height="100%" mx={[5, 12]} py={[null, 8]}>
            <PageLayoutHeader backUrl={backUrl} />
            {isDefined(error) ? (
                <Heading textAlign="center">{t('common.error.title')}</Heading>
            ) : !isLoading ? (
                <>
                    {isSmallScreen ? <VStack style={{ position: 'absolute', right: 0 }}>{headerAction}</VStack> : null}
                    <Box
                        key={1}
                        bg="secondary.900"
                        borderRadius={10}
                        flexGrow={0}
                        flexShrink={0}
                        mb={10}
                        px={8}
                        py={5}
                        testID={testID}
                    >
                        <HStack alignItems="center" justifyContent="space-between">
                            <HStack alignItems="center" w={isSmallScreen ? 'full' : null}>
                                <Image alt="title image" size={16} src={imageSrc} />
                                <Container flexGrow={1} ml={[5, 10]}>
                                    <Heading fontSize={25} fontWeight={600} mb={[0, 2]} w="full">
                                        {title}
                                    </Heading>
                                    {headerChildren}
                                </Container>
                            </HStack>
                            {!isSmallScreen ? headerAction : null}
                        </HStack>
                    </Box>
                    {children}
                </>
            ) : (
                <Spinner size="lg" />
            )}
        </VStack>
    ) : (
        <VStack height="100%" mx={[5, 12]} py={[null, 8]}>
            {children}
        </VStack>
    );
};

export default BaseDetails;
