import { extendTheme } from 'native-base';

const nativeBaseTheme = extendTheme({
    colors: {
        background: {
            start: '#1C1E30',
            end: '#0D101A',
        },
        backgroundLevel1: {
            primary: '#2E2E48',
            secondary: '#295971',
        },
        primary: {
            200: '#7E73FF',
            300: '#4E81EB',
            500: '#4B63F0',
            600: '#5B4DFF',
            700: '#441DFC',
        },
        secondary: {
            500: '#3ACFBA',
            800: '#50505f',
            900: '#373747',
        },
        accent: {
            500: '#03DA99',
        },
    },
    components: {
        Alert: {
            baseStyle: {
                borderRadius: '10',
            },
        },
        Badge: {
            baseStyle: {
                _text: {
                    fontWeight: 600,
                },
            },
            variants: {
                subtle: {
                    borderRadius: '20',
                },
                outline: {
                    borderRadius: '20',
                },
                solid: {
                    borderRadius: '5',
                    px: '2',
                    py: '1',
                },
            },
        },
        Button: {
            baseStyle: {
                borderRadius: 'full',
            },
            variants: {
                ghost: {
                    _hover: {
                        bg: `blueGray.200:alpha.10`,
                    },
                },
                outline: {
                    color: 'white',
                    _text: {
                        color: 'white',
                    },
                    style: {
                        border: '1px solid',
                    },
                },
                link: {
                    _dark: {
                        color: 'white',
                        _text: {
                            color: 'white',
                        },
                    },
                    textDecorationLine: 'underline',
                    width: 'max-content',
                    _hover: {
                        color: 'primary.200',
                        _text: {
                            color: 'primary.200',
                        },
                    },
                },
            },
        },
        Checkbox: {
            baseStyle: {
                _dark: {
                    bg: 'white',
                    borderColor: 'white',
                },
                _text: {
                    fontSize: 'sm',
                    color: 'white',
                },
            },
            defaultProps: {
                size: 'sm',
            },
        },
        FormControlLabel: {
            baseStyle: {
                astrickColor: 'white',
                marginLeft: '4px',
                marginTop: '20px',
            },
            defaultProps: {
                _text: {
                    color: 'light.50',
                },
            },
        },
        FormControlErrorMessage: {
            baseStyle: {
                marginLeft: '4',
            },
        },
        FormControlHelperText: {
            baseStyle: {
                marginX: '4',
            },
        },
        Icon: {
            defaultProps: {
                color: 'gray.50',
            },
        },
        IconButton: {
            defaultProps: {
                _icon: {
                    color: 'gray.50',
                },
            },
        },
        Input: {
            defaultProps: {
                variant: 'rounded',
                bg: 'white',
                color: 'black',
                h: 10,
                selectionColor: 'black',
                fontSize: 14,
                px: 4,
                _focus: {
                    bg: 'white',
                },
                _hover: {
                    bg: 'white',
                },
                _invalid: {
                    borderColor: 'red.500',
                    borderWidth: 2,
                },
                _input: {
                    bg: 'white',
                    flex: 1,
                },
            },
            variants: {
                filled: {
                    borderRadius: 'full',
                },
            },
        },
        Spinner: {
            defaultProps: {
                color: 'accent.500',
            },
        },
        Modal: {
            baseStyle: {
                _backdrop: {
                    bg: 'light.50',
                },
            },
            defaultProps: {
                size: 'xl',
            },
        },
        ModalContent: {
            baseStyle: {
                bgColor: 'backgroundLevel1.primary',
                maxWidth: '720',
                paddingTop: 8,
                paddingLeft: 12,
                paddingRight: 12,
                paddingBottom: 8,
            },
        },
        ModalHeader: {
            baseStyle: {
                borderBottomWidth: '0',
                _text: {
                    fontSize: '3xl',
                    fontWeight: 'semibold',
                },
            },
        },
        ModalCloseButton: {
            baseStyle: {
                top: '12',
                right: '12',
                p: '3',
                _icon: {
                    size: '4',
                },
            },
        },
        ModalFooter: {
            baseStyle: {
                bgColor: 'backgroundLevel1.primary',
                justifyContent: 'flex-start',
            },
        },
    },
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
    },
    fontConfig: {
        Poppins: {
            100: {
                italic: 'Poppins-ThinItalic',
                normal: 'Poppins-Thin',
            },
            200: {
                italic: 'Poppins-ExtraLightItalic',
                normal: 'Poppins-ExtraLight',
            },
            300: {
                italic: 'Poppins-LightItalic',
                normal: 'Poppins-Light',
            },
            400: {
                italic: 'Poppins-Italic',
                normal: 'Poppins-Regular',
            },
            500: {
                italic: 'Poppins-MediumItalic',
                normal: 'Poppins-Medium',
            },
            600: {
                italic: 'Poppins-SemiBoldItalic',
                normal: 'Poppins-SemiBold',
            },
            700: {
                italic: 'Poppins-BoldItalic',
                normal: 'Poppins-Bold',
            },
            800: {
                italic: 'Poppins-ExtraBoldItalic',
                normal: 'Poppins-ExtraBold',
            },
            900: {
                italic: 'Poppins-BlackItalic',
                normal: 'Poppins-Black',
            },
        },
    },
    fonts: {
        body: 'Poppins',
        heading: 'Poppins',
        mono: 'Poppins',
    },
});

type NativeBaseThemeType = typeof nativeBaseTheme;
//
declare module 'native-base' {
    type INativeBaseTheme = NativeBaseThemeType;
}

export default nativeBaseTheme;
