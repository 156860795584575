import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18n from '../../i18n/i18next';
import providers from '../../providers';
import { GetProfileWSOutput, Profile } from '../../providers/profile/types';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';
import { ProfileState } from './types';

const initialState: ProfileState = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    cookieAccepted: true,
};

export const getProfileData = createAsyncThunk('profile/getProfileData', () =>
    providers.httpService.get<GetProfileWSOutput>('/api/v1/users/me'),
);

export const editProfile = createAsyncThunk('profile/editProfile', (payload: Partial<Profile>, { getState }) => {
    const { profile } = getState() as RootState;

    return providers.profileProvider.editProfile({ ...profile, ...payload });
});

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProfileData.fulfilled, (state, action) => {
            i18n.changeLanguage(action.payload.language);

            return { ...action.payload };
        });
        builder.addCase(editProfile.fulfilled, (state, action) => {
            i18n.changeLanguage(action.payload.language);

            return { ...action.payload };
        });
    },
});

export const profileState = (state: RootState) => state.profile;

export default profileSlice.reducer;
