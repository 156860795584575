import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { HStack, Heading, Icon, Link, Text, VStack } from 'native-base';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GradientButton from '../../components/Button/GradientButton/GradientButton';
import ExpenseUsersMonthSelection from '../../components/ExpenseUsersMonthSelection/ExpenseUsersMonthSelection';
import FormModal from '../../components/FormModal/FormModal';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import { ExportType } from '../Sites/types';
import { ReactComponent as LinkIcon } from '../../assets/icons/link-square.svg';
import { ReactComponent as IconMail } from '../../assets/icons/mail.svg';
import { ReactComponent as IconPhone } from '../../assets/icons/phone.svg';

/**
 * Page with tools for managers of the company
 */
const ToolsPage = () => {
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);
    const [modalOpen, setModalOpen] = useState(false);
    const [exportType, setExportType] = useState<ExportType>(ExportType.EXPENSE);
    const phone = process.env.REACT_APP_SAV_PHONE;
    const mail = process.env.REACT_APP_SAV_MAIL;

    const title = useMemo(
        () => t(exportType === ExportType.EXPENSE ? 'tools.expenses.title' : 'tools.charges.title'),
        [exportType],
    );

    const open = (_exportType: ExportType) => {
        setExportType(_exportType);
        setModalOpen(true);
    };

    return (
        <VStack mx={[10, 32]} my={20}>
            {company !== undefined ? (
                <FormModal
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    testID="expenses-month-modal"
                    title={title}
                >
                    <ExpenseUsersMonthSelection
                        closeModal={() => setModalOpen(false)}
                        company={company}
                        exportType={exportType}
                    />
                </FormModal>
            ) : null}
            <PageLayoutHeader title={t('tools.title')} />
            <VStack space={8}>
                <VStack alignItems="start" space={2} zIndex={-1}>
                    <Heading fontWeight="600" size="md">
                        {t('tools.expenses.title')}
                    </Heading>
                    <Text mb={2}>{t('tools.expenses.description')}</Text>
                    <GradientButton
                        fontSize="sm"
                        label={t('tools.expenses.export')}
                        onClick={() => open(ExportType.EXPENSE)}
                    />
                </VStack>
                <VStack alignItems="start" space={2}>
                    <Heading fontWeight="600" size="md">
                        {t('tools.charges.title')}
                    </Heading>
                    <Text mb={2}>{t('tools.charges.description')}</Text>
                    <GradientButton
                        fontSize="sm"
                        label={t('tools.charges.export')}
                        onClick={() => open(ExportType.CHARGE)}
                    />
                </VStack>
                <VStack alignItems="start" space={2}>
                    <Heading fontWeight="600" size="md">
                        {t('tools.contactUs.title')}
                    </Heading>
                    <Text mb={2}>{t('tools.contactUs.description')}</Text>
                    <HStack space={4}>
                        {phone && isValidPhoneNumber(phone) ? (
                            <Link href={`tel:${phone}`} testID="link-phone">
                                <GradientButton
                                    fontSize="sm"
                                    icon={<Icon as={IconPhone} size="sm" />}
                                    label={
                                        <HStack alignItems="center" space={2}>
                                            <Icon as={IconPhone} size="sm" />
                                            <Text>{parsePhoneNumber(phone).formatInternational()}</Text>
                                        </HStack>
                                    }
                                />
                            </Link>
                        ) : undefined}
                        {mail ? (
                            <Link href={`mailto:${mail}`} testID="link-mail">
                                <GradientButton
                                    fontSize="sm"
                                    icon={<Icon as={IconMail} size="sm" />}
                                    label={
                                        <HStack alignItems="center" space={2}>
                                            <Icon as={IconMail} size="sm" />
                                            <Text>{mail}</Text>
                                        </HStack>
                                    }
                                />
                            </Link>
                        ) : undefined}
                    </HStack>
                    {/*  */}
                </VStack>
                <Link
                    _text={{ color: 'white' }}
                    href="https://www.sparklin.io/cgu-app"
                    isUnderlined={false}
                    width="max-content"
                    isExternal
                >
                    <HStack space={1} style={{ alignItems: 'center' }}>
                        <Text fontSize="xl" fontWeight={600}>
                            {t('common.terms_of_service.label')}
                        </Text>
                        <LinkIcon />
                    </HStack>
                </Link>
            </VStack>
        </VStack>
    );
};

export default ToolsPage;
