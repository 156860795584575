import { Center, useMediaQuery } from 'native-base';
import React, { FC } from 'react';
import OtpInput, { InputProps } from 'react-otp-input';
import { SCREENSIZE } from '../../global';
import { splice } from '../../utils/string';
import styles from '../CustomOtpInput/CustomOtpInput.module.css';

interface ReadableInputProps {
    onChange: (value: ((prevState: string) => string) | string) => void;
    value: string;
    numInputs?: number;
    onSubmit?: () => void;
}

const ReadableInput: FC<ReadableInputProps> = ({ value, onChange, onSubmit = () => {}, numInputs = 11 }) => {
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    const handleChange = (v: string) => {
        let readable = v.toUpperCase();
        if (readable.length === 1 && readable === '0') {
            readable = 'O';
        }

        if (readable.length >= 3 && readable.charAt(2) !== '-') {
            readable = splice(readable, 2, 1, '-');
        }

        onChange(readable);

        if (readable.length === numInputs) {
            onSubmit();
        }
    };

    return (
        <Center>
            <OtpInput
                inputStyle={!isSmallScreen ? styles.input : styles.mobile}
                numInputs={numInputs}
                onChange={handleChange}
                renderInput={(props: InputProps, index) => {
                    if (index === 1 && isSmallScreen) {
                        return <input {...props} min="0" pattern="[0-9]*" type="number" />;
                    }
                    if (index === 2) {
                        return <input {...props} value="-" />;
                    }

                    return <input {...props} />;
                }}
                value={value}
                shouldAutoFocus
            />
        </Center>
    );
};

export default ReadableInput;
