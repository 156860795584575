import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { t } from 'i18next';
import handleError from '../../components/ErrorToast/ErrorToast';
import { invokeSuccessToast } from '../../components/Toast/Toast';
import { EditSocketPayload } from '../../pages/Sockets/types';
import providers from '../../providers';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../store';
import { SocketsState } from './type';

const initialState: SocketsState = {
    sockets: [],
    loading: false,
};

const displaySuccessToast = (name: string) =>
    invokeSuccessToast(t('common.success.title'), t('sockets.edit.success.description', { name }));

export const getSockets = createAsyncThunk('socket/getSockets', async (companyId: CompanyId) =>
    providers.socketsProvider.getSockets(companyId),
);

export const editSocket = createAsyncThunk('socket/editSocket', async (payload: EditSocketPayload) => {
    try {
        const response = await providers.socketsProvider.editSocket(payload);
        displaySuccessToast(payload.name);

        return response;
    } catch (err) {
        handleError(err as Error);
        throw err;
    }
});

const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getSockets.fulfilled, (state, action) => ({
            ...state,
            sockets: action.payload,
            loading: true,
        }));
        builder.addCase(editSocket.fulfilled, (state, action) => {
            const editedSocket = action.payload;
            const index = state.sockets.findIndex(({ id }) => id === editedSocket.id);
            if (index > -1) {
                Object.assign(state.sockets[index], { ...editedSocket });
            }
        });
    },
});

export const socketSelect = (state: RootState) => state.socket.sockets;
export const socketLoading = (state: RootState) => state.socket.loading;

export default socketSlice.reducer;
