import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { isAuthedSelect } from '../../store/auth/auth';

/**
 * Component to wrap routes to make them accessible only when logged.
 */
const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const isAuthed = useSelector(isAuthedSelect);
    const location = useLocation();

    if (!isAuthed) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate state={{ from: location }} to="/login" replace />;
    }

    return children;
};

export default RequireAuth;
