import { Badge, Pressable, Row, Spinner } from 'native-base';
import React from 'react';

export interface ChipSelectorProps<T = string | number> {
    chipList: { id: T; name: string }[] | undefined;
    selectedChips: T[];
    onChange: (selectedIds: T[]) => void;
}

/**
 * Component which displays a list of chips to allow users to select one or several of them.
 */
const ChipSelector = ({ chipList, selectedChips, onChange }: ChipSelectorProps) => {
    const onChipClicked = (chipId: string | number) => {
        const indexOfChipId = selectedChips.indexOf(chipId);
        if (indexOfChipId !== -1) {
            const newSelectedChips = [...selectedChips];
            newSelectedChips.splice(indexOfChipId, 1);
            onChange(newSelectedChips);
        } else {
            onChange([...selectedChips, chipId]);
        }
    };

    return (
        <Row flexWrap="wrap">
            {chipList ? (
                chipList.map(({ id, name }) => {
                    const isChecked = selectedChips.includes(id);

                    return (
                        <Pressable key={id} onPress={() => onChipClicked(id)}>
                            <Badge
                                _text={{
                                    color: isChecked ? 'white' : 'blueGray.200',
                                    fontSize: 14,
                                    fontWeight: 400,
                                }}
                                bg={isChecked ? 'primary.700' : 'transparent'}
                                borderColor={isChecked ? 'primary.700' : 'blueGray.600'}
                                borderWidth={2}
                                color="white"
                                flexDirection="row"
                                margin={1}
                                variant="solid"
                            >
                                {name}
                            </Badge>
                        </Pressable>
                    );
                })
            ) : (
                <Row justifyContent="center" w="full">
                    <Spinner alignSelf="center" size="sm" />
                </Row>
            )}
        </Row>
    );
};

export default ChipSelector;
