import { VStack, Image, HStack, Text, Pressable, Badge, Box } from 'native-base';
import React, { FC } from 'react';
import EditButton from '../Button/EditButton';

interface BaseCardProps {
    title: string;
    imageSrc: string;
    isPressable?: boolean;
    disabled?: boolean;
    onTouch?: VoidFunction;
    badge?: string | JSX.Element | undefined;
    icon?: JSX.Element;
    badgeColor?: string;
    children?: React.ReactNode;
    testID?: string;
    onEdit?: VoidFunction;
}

const BaseCard: FC<BaseCardProps> = ({
    title,
    imageSrc,
    isPressable = false,
    disabled = false,
    badgeColor = 'teal.500',
    badge,
    icon,
    testID = 'base-card',
    onTouch,
    children,
    onEdit,
}) => {
    let badgeComponent;

    if (badge) {
        badgeComponent =
            typeof badge === 'object' ? (
                badge
            ) : (
                <Badge bg={badgeColor} px={3}>
                    {badge}
                </Badge>
            );
    }
    const content = (
        <div onClick={onTouch} onKeyPress={onTouch} role="gridcell" tabIndex={-1}>
            <VStack h={onEdit ? '9rem' : null} m={3}>
                <HStack justifyContent={icon ? 'space-between' : 'flex-end'} w="full">
                    {icon ? <Box>{icon}</Box> : null}
                    {badgeComponent}
                </HStack>
                <Box bottom={4} position="absolute" right={0} zIndex={999}>
                    {onEdit ? <EditButton label="edit" onPress={onEdit} variant="outline" mini /> : null}
                </Box>
                <HStack alignItems="center" my={3}>
                    <Image alt={title} resizeMode="contain" size={16} src={imageSrc} width="30%" />
                    <VStack maxW="60%" pl={3}>
                        <Text fontSize="sm" fontWeight="600" overflowX="hidden">
                            {title}
                        </Text>
                        {children}
                    </VStack>
                </HStack>
            </VStack>
        </div>
    );

    return isPressable ? (
        <Pressable
            _hover={{ bg: 'secondary.800' }}
            bg="secondary.900"
            borderRadius={10}
            disabled={disabled || !onTouch}
            justifyContent="center"
            testID={testID}
        >
            {content}
        </Pressable>
    ) : (
        <Box bg="secondary.900" borderRadius={10} justifyContent="center" testID={testID}>
            {content}
        </Box>
    );
};

export default BaseCard;
