import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { isDefined } from '@sparklin/react.api.utils.is-defined';
import getSymbolFromCurrency from 'currency-symbol-map';
import {
    Box,
    Container,
    FormControl,
    Input,
    Text,
    VStack,
    WarningOutlineIcon,
    HStack,
    Switch,
    Spinner,
} from 'native-base';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import * as yup from 'yup';
import StripeBadge from '../../components/Badge/StripeBadge';
import GradientButton from '../../components/Button/GradientButton/GradientButton';
import handleError from '../../components/ErrorToast/ErrorToast';
import { PageLayoutHeader } from '../../components/PageLayout/PageLayout';
import { invokeSuccessToast } from '../../components/Toast/Toast';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import { Configuration } from './types';
import { ReactComponent as BankIcon } from '../../assets/icons/bank.svg';
import externeLinkIcon from '../../assets/images/externe-link.png';

/**
 * Page to change the configuration of the selected company.
 */
const ConfigurationPage = () => {
    const { t, i18n } = useTranslation();
    const styleText = { color: 'white' };
    const company = useAppSelector(companySelect);
    const hasMounted = useRef(false);
    const initialvalues = {
        initialPaymentActivated: false,
        status: '',
        bankName: '',
        currency: '',
        last4: '',
        url: '',
    };
    const pendingButton = {
        pendingButtonConfiguration: false,
        pendingButtonDashBoard: false,
    };
    const [stripeData, setStripeData] = useState(initialvalues);
    const [updatePending, setUpdatePending] = useState(pendingButton);
    const [isChecked, setIsChecked] = useState(false);
    const costPerKWhRegex = new RegExp(
        `^([0-9s]+)(${t('companyConfiguration.costPerKWh.decimalSeparator')}([0-9]{1,2}))?`,
        'g',
    );
    const [loading, setLoading] = useState(false);
    const [maxUCAmount, setMaxUCAmount] = useState<number | undefined>();
    const [currency, setCurrency] = useState<Configuration['currency']>('EUR');
    const statusStripeEdit = ['Restricted soon', 'Enabled', 'Complete'];
    // Special parsing for the price per kWh (from string to float)
    const parseCostPerKWh = (v: string) =>
        parseFloat(v.replaceAll(/\s/g, '').replace(t('companyConfiguration.costPerKWh.decimalSeparator'), '.'));
    const formSchema = yup.object({
        costPerKWh: yup
            .string()
            .required(t('companyConfiguration.costPerKWh.required'))
            .test({
                name: 'isValidCost',
                test(value, ctx) {
                    if (!costPerKWhRegex.test(value)) {
                        return ctx.createError({ message: t('companyConfiguration.costPerKWh.format') });
                    }

                    if (isDefined(maxUCAmount) && parseCostPerKWh(value) > maxUCAmount) {
                        return ctx.createError({
                            message: t('companyConfiguration.costPerKWh.tooHigh', {
                                val: maxUCAmount,
                                currency,
                                locale: i18n.language,
                            }),
                        });
                    }

                    return true;
                },
            }),
        paymentActivated: yup.boolean(),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm<yup.InferType<typeof formSchema>>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(formSchema),
        defaultValues: {
            costPerKWh: '',
            paymentActivated: false,
        },
    });

    /**
     * Helper used to convert a Configuration to the correct shape for the form.
     * The main idea is to convert the cost per kWh from a float to a correctly-formated string.
     */
    const mapConfiguration = (companyConfiguration: Configuration): yup.InferType<typeof formSchema> => ({
        costPerKWh: `${new Intl.NumberFormat(i18n.language).format(companyConfiguration.costPerKWh)}`,
        paymentActivated: companyConfiguration.paymentActivated,
    });

    /**
     * Effect run on startup, to get the initial value of company configuration
     */
    useEffect(() => {
        if (company) {
            providers.companiesProvider
                .getConfiguration(company.id)
                .then((companyConfig) => {
                    reset(mapConfiguration(companyConfig));
                    setMaxUCAmount(companyConfig.maxUCAmount);
                    setCurrency(companyConfig.currency);
                    setStripeData({
                        initialPaymentActivated: companyConfig.paymentActivated,
                        status: companyConfig.accountInformations?.status || '',
                        bankName: companyConfig.accountInformations?.bankName || '',
                        currency: companyConfig.accountInformations?.currency || '',
                        last4: companyConfig.accountInformations?.last4 || '',
                        url: companyConfig.accountInformations?.url || '',
                    });
                    setLoading(true);
                })
                .catch(handleError);
        }
    }, [company]);

    useEffect(() => {
        if (hasMounted.current) {
            setIsChecked(stripeData.initialPaymentActivated);
        } else {
            hasMounted.current = true;
        }
    }, [stripeData.initialPaymentActivated]);

    const handleChange = handleSubmit((data) => {
        if (company) {
            providers.companiesProvider
                .putConfiguration(company.id, {
                    costPerKWh: parseCostPerKWh(data.costPerKWh),
                    paymentActivated: !!data.paymentActivated,
                })
                .then((companyConfig) => {
                    reset(mapConfiguration(companyConfig));
                    setStripeData({ ...stripeData, initialPaymentActivated: companyConfig.paymentActivated });
                })
                .then(() => {
                    invokeSuccessToast(
                        t('companyConfiguration.successToast.title'),
                        t('companyConfiguration.successToast.message'),
                    );
                })
                .catch(handleError);
        }
    });

    const redirectToBeneficiaryAccountLink = (): void => {
        if (company) {
            setUpdatePending({ ...updatePending, pendingButtonConfiguration: true });
            providers.companiesProvider
                .getBeneficiaryAccountLink(company.id)
                .then((beneficiaryAccountLink) => {
                    window.location.href = beneficiaryAccountLink.url;
                })
                .catch(handleError)
                .finally(() => {
                    setUpdatePending({ ...updatePending, pendingButtonConfiguration: false });
                });
        }
    };

    return (
        <VStack mx={[10, 32]} my={20}>
            <PageLayoutHeader title={t('companyConfiguration.title')} />
            {loading ? (
                <Container alignSelf={['center', 'center', null]} maxWidth={['300px', null]} zIndex="-1">
                    <FormControl isInvalid={errors.costPerKWh !== undefined}>
                        <Controller
                            control={control}
                            name="costPerKWh"
                            render={({ field: { onChange, value } }) => (
                                <HStack px={4} space={2}>
                                    <NumericFormat
                                        allowNegative={false}
                                        borderColor="white"
                                        borderRadius={20}
                                        borderWidth={1}
                                        color="white"
                                        customInput={Input}
                                        decimalScale={2}
                                        decimalSeparator={t('companyConfiguration.costPerKWh.decimalSeparator')}
                                        h={58}
                                        keyboardType="phone-pad"
                                        onBlur={() => {
                                            handleChange();
                                        }}
                                        onChange={onChange}
                                        placeholder={t('companyConfiguration.costPerKWh.placeholder')}
                                        style={{
                                            backgroundColor: '#191b2b',
                                            textAlign: 'center',
                                            fontSize: 20,
                                        }}
                                        thousandSeparator={t('companyConfiguration.costPerKWh.thousandSeparator')}
                                        value={value}
                                        w={101}
                                    />
                                    <Text alignSelf="center" fontSize={18}>
                                        {t('companyConfiguration.costPerKWh.suffix', {
                                            currency: getSymbolFromCurrency(currency),
                                        })}
                                    </Text>
                                </HStack>
                            )}
                        />
                        <FormControl.HelperText>{t('companyConfiguration.costPerKWh.helper')}</FormControl.HelperText>
                        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />} testID="error">
                            {errors.costPerKWh?.message}
                        </FormControl.ErrorMessage>
                    </FormControl>
                    <VStack px={4}>
                        <FormControl>
                            <Controller
                                control={control}
                                name="paymentActivated"
                                render={({ field: { onChange } }) => (
                                    <HStack mt={2} space={3}>
                                        <Text>{t('companyConfiguration.paymentActivated.label')}</Text>
                                        <Switch
                                            colorScheme="primary"
                                            isChecked={isChecked}
                                            onToggle={(e) => {
                                                onChange(e);
                                                handleChange();
                                            }}
                                        />
                                    </HStack>
                                )}
                            />
                            <FormControl.HelperText mb={isChecked ? 5 : 0} mt={2} mx={0}>
                                {t('companyConfiguration.paymentActivated.helper')}
                            </FormControl.HelperText>
                        </FormControl>
                        {isChecked && (
                            <VStack pb={5}>
                                <HStack space={3}>
                                    <Text>{t('companyConfiguration.beneficiaryAccount.statusAccount')}</Text>
                                    <StripeBadge
                                        status={
                                            stripeData.status === 'Restricted soon'
                                                ? 'RestrictedSoon'
                                                : stripeData.status
                                        }
                                    />
                                </HStack>
                                {stripeData.status !== 'Complete' && stripeData.status !== 'Enabled' && (
                                    <Text color="#a3a3a3" fontSize={12} mt={2} textAlign="justify">
                                        {t('companyConfiguration.beneficiaryAccount.informationMissing')}
                                    </Text>
                                )}
                                <Box mt={5} width="max-content">
                                    <GradientButton
                                        fontSize="xs"
                                        iconRight={externeLinkIcon}
                                        isLoading={updatePending.pendingButtonConfiguration}
                                        label={
                                            statusStripeEdit.includes(stripeData.status)
                                                ? t('companyConfiguration.beneficiaryAccount.editButton')
                                                : t('companyConfiguration.beneficiaryAccount.configurationButton')
                                        }
                                        onClick={() => redirectToBeneficiaryAccountLink()}
                                    />
                                </Box>
                                <Text mt={5}>{t('companyConfiguration.beneficiaryAccount.paymentAccount')}</Text>
                                {stripeData.bankName && stripeData.currency && stripeData.last4 ? (
                                    <HStack
                                        bg="#191b2b"
                                        borderColor="#E9E9EA"
                                        borderRadius={10}
                                        borderWidth={1}
                                        mt={2}
                                        p={3}
                                        space={5}
                                        width="max-content"
                                    >
                                        <BankIcon
                                            height={25}
                                            style={{ margin: 10, backgroundColor: 'white', padding: 5 }}
                                            width={25}
                                        />
                                        <VStack>
                                            <HStack mb={2} space={3}>
                                                <Text style={styleText}>{stripeData.bankName}</Text>
                                                <Text bg="white" borderRadius={10} color="#2f2e48" px={2}>
                                                    {stripeData.currency.toUpperCase()}
                                                </Text>
                                            </HStack>
                                            <Text style={styleText}>•••• {stripeData.last4}</Text>
                                        </VStack>
                                    </HStack>
                                ) : (
                                    <Text color="#a3a3a3" fontSize={12} mt={2} textAlign="justify">
                                        {t('companyConfiguration.beneficiaryAccount.bankAccountUnavailable')}
                                    </Text>
                                )}
                            </VStack>
                        )}
                        <Box width="max-content">
                            {stripeData.url && isChecked ? (
                                <GradientButton
                                    fontSize="xs"
                                    iconRight={externeLinkIcon}
                                    isLoading={updatePending.pendingButtonDashBoard}
                                    label={t('companyConfiguration.beneficiaryAccount.dashboardButton')}
                                    onClick={() => {
                                        setUpdatePending({ ...updatePending, pendingButtonDashBoard: true });
                                        setTimeout(() => {
                                            window.location.href = stripeData.url;
                                            setUpdatePending({ ...updatePending, pendingButtonDashBoard: false });
                                        }, 1000);
                                    }}
                                />
                            ) : undefined}
                        </Box>
                    </VStack>
                </Container>
            ) : (
                <Spinner size="lg" />
            )}
        </VStack>
    );
};

export default ConfigurationPage;
