import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { FormControl, Input, Modal, WarningOutlineIcon } from 'native-base';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import GradientButton from '../../../components/Button/GradientButton/GradientButton';
import { companySelect } from '../../../store/auth/auth';
import { useAppSelector } from '../../../store/hooks';
import { editSocket } from '../../../store/socket/socket';
import { AppDispatch } from '../../../store/store';
import { EditSocketPayload } from '../types';
import styles from './EditSocketModalContent.module.css';

const EditSocketModalContent = ({ onSubmitted, socket }: { onSubmitted: VoidFunction; socket: EditSocketPayload }) => {
    const company = useAppSelector(companySelect);
    const [isRequestPending, setIsRequestPending] = useState<boolean>(false);
    const dispatch = useDispatch<AppDispatch>();

    const formSchema = yup.object({
        name: yup.string().required(t('sockets.edit.name.required')).max(19, t('sockets.edit.name.maxlength')),
        description: yup.string().max(50, t('sockets.edit.description.maxlength')),
        paymentEnabled: yup.boolean().default(true),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<yup.InferType<typeof formSchema>>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(formSchema),
        defaultValues: { name: socket.name, description: socket.description },
    });

    const onValidate = handleSubmit(async (data) => {
        if (company) {
            setIsRequestPending(true);
            dispatch(editSocket({ id: socket.id, name: data.name, description: data.description }));
            onSubmitted();
        }
    });

    return (
        <>
            <Modal.Body>
                <div className={styles.grid}>
                    <FormControl isInvalid={errors.name !== undefined}>
                        <FormControl.Label>{t('sockets.edit.name.label')}</FormControl.Label>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field: { onBlur, onChange, ref, value } }) => (
                                <Input
                                    ref={ref}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    placeholder={t('sockets.edit.name.placeholder')}
                                    testID="input-rename-socket"
                                    value={value}
                                    variant="rounded"
                                />
                            )}
                        />
                        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                            {errors.name?.message}
                        </FormControl.ErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.description !== undefined}>
                        <FormControl.Label>{t('sockets.edit.description.label')}</FormControl.Label>
                        <Controller
                            control={control}
                            name="description"
                            render={({ field: { onBlur, onChange, ref, value } }) => (
                                <Input
                                    ref={ref}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    placeholder={t('sockets.edit.description.placeholder')}
                                    testID="input-description-socket"
                                    value={value}
                                    variant="rounded"
                                />
                            )}
                        />
                        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                            {errors.description?.message}
                        </FormControl.ErrorMessage>
                    </FormControl>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <GradientButton
                    fontSize="sm"
                    isLoading={isRequestPending}
                    label={t('sockets.edit.button')}
                    onClick={onValidate}
                />
            </Modal.Footer>
        </>
    );
};

export default EditSocketModalContent;
