import { Input } from 'native-base';
import React, { ForwardedRef } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './CustomPhoneInput.module.css';

type ForwardedRefNotNull<T> = Exclude<ForwardedRef<T>, null> | undefined;

export const StyledRefInput = React.forwardRef<
    HTMLInputElement,
    { value?: string; onChange?: (value: string | undefined) => void | undefined; placeholder: string; testID?: string }
>((props, ref) => {
    const mRef = ref as ForwardedRefNotNull<HTMLInputElement>;

    return (
        <Input
            ref={mRef}
            flexGrow={1}
            keyboardType="phone-pad"
            onChange={(e) => props.onChange?.(e.nativeEvent.text)}
            placeholder={props.placeholder}
            testID={props.testID}
            type="text"
            value={props.value}
            variant="rounded"
            w="1em"
        />
    );
});

StyledRefInput.displayName = 'StyledRefInput';

export interface CustomPhoneInputProps {
    value: string | undefined;
    onChange: (value: string | undefined) => void;
    placeholder?: string;
    testID?: string;
}

const CustomPhoneInput = ({ value, onChange, placeholder, testID }: CustomPhoneInputProps) => (
    <PhoneInput
        autoCompleteType="tel"
        defaultCountry="FR"
        inputComponent={StyledRefInput}
        onChange={onChange}
        placeholder={placeholder}
        testID={testID}
        value={value}
        international
    />
);

CustomPhoneInput.displayName = 'CustomPhoneInput';

export default CustomPhoneInput;
