import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PageLayout from './components/PageLayout/PageLayout';
import RequireAuth from './components/RequireAuth/RequireAuth';
import CommunitiesPage from './pages/Communities/Communities';
import CommunityDetailsPage from './pages/CommunityDetails/CommunityDetails';
import HomePage from './pages/Home/Home';
import LoginPage from './pages/Login/Login';
import ConfigurationPage from './pages/Monetization/Monetization';
import ReadablePage from './pages/Setup/Readable';
import RedirectPage from './pages/Setup/Redirect';
import SetupPage from './pages/Setup/Setup';
import SiteDetailsPage from './pages/SiteDetails/SiteDetails';
import SitesPage from './pages/Sites/Sites';
import SocketsPage from './pages/Sockets/Sockets';
import ToolsPage from './pages/Tools/Tools';
import UserDetailsPage from './pages/UserDetails/UserDetails';
import UsersPage from './pages/Users/Users';

import './App.css';

const App = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div className="App">
            <Routes>
                <Route path="/">
                    <Route element={<LoginPage />} path="login" />
                    <Route element={<ReadablePage />} path="socket/:code?" />
                    <Route element={<RedirectPage />} path="go-to/:id" />
                    <Route element={<SetupPage />} path="setup/:id" />
                    <Route
                        element={
                            <RequireAuth>
                                <PageLayout />
                            </RequireAuth>
                        }
                        path="/"
                    >
                        <Route element={<HomePage />} index />
                        <Route element={<UsersPage />} path="/users" />
                        <Route element={<UserDetailsPage />} path="/users/:id" />
                        <Route element={<CommunitiesPage />} path="/communities" />
                        <Route element={<CommunityDetailsPage />} path="/communities/:id" />
                        <Route element={<SocketsPage />} path="/sockets" />
                        <Route element={<SitesPage />} path="/sites" />
                        <Route element={<SiteDetailsPage />} path="/sites/:id" />
                        <Route element={<ToolsPage />} path="/tools" />
                        <Route element={<ConfigurationPage />} path="/company-settings" />
                    </Route>
                </Route>
            </Routes>
            <ToastContainer closeButton={false} position="bottom-right" hideProgressBar />
        </div>
    );
};
export default App;
