import { yupResolver } from '@hookform/resolvers/yup';
import { Box, FormControl, Input, Modal, WarningOutlineIcon } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import GradientButton from '../../../components/Button/GradientButton/GradientButton';
import SelectButton from '../../../components/Button/SelectButton';
import ChipSelector from '../../../components/ChipSelector/ChipSelector';
import handleError from '../../../components/ErrorToast/ErrorToast';
import { invokeSuccessToast } from '../../../components/Toast/Toast';
import providers from '../../../providers';
import { companySelect } from '../../../store/auth/auth';
import { useAppSelector } from '../../../store/hooks';
import { SiteDetails } from '../../SiteDetails/types';
import { SiteType } from '../types';
import { EditSiteCommunity, SiteNameAlreadyExistsException } from './types';
import styles from './EditSiteModalContent.module.css';

/**
 * Form used to edit a site
 */
const EditSiteModalContent = ({
    editingSite,
    onSubmitted,
}: {
    editingSite: SiteDetails; // The site we want to edit
    onSubmitted: VoidFunction;
}) => {
    const { t } = useTranslation();

    const [communities, setCommunities] = useState<EditSiteCommunity[] | undefined>(undefined);
    const company = useAppSelector(companySelect);
    const [isRequestPending, setIsRequestPending] = useState<boolean>(false);

    const options = {
        [SiteType.PRIVATE]: { label: t('sites.edit.type.private'), value: SiteType.PRIVATE },
        [SiteType.SHARED]: { label: t('sites.edit.type.shared'), value: SiteType.SHARED },
    };

    const formSchema = yup.object({
        name: yup.string().required(t('sites.edit.error.name.required')),
        type: yup.mixed<SiteType>().oneOf(Object.values(SiteType)).required(t('sites.edit.error.type.required')),
        communities: yup.array().of(yup.number().required()),
    });

    const mapSiteDetailsToForm = (details: SiteDetails): yup.InferType<typeof formSchema> => ({
        name: details.name,
        type: details.type,
        communities: details.communities.map((community) => community.id),
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        setError,
    } = useForm<yup.InferType<typeof formSchema>>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(formSchema),
        defaultValues: mapSiteDetailsToForm(editingSite),
    });

    /**
     * Effect to get the communities displayed in the form
     */
    useEffect(() => {
        if (company) {
            providers.communitiesProvider.getCommunities(company.id).then(setCommunities).catch(handleError);
        } else {
            setCommunities([]);
        }
    }, [editingSite]);

    /**
     * Displays a toast about the success of form submission.
     * @param name the site name displayed in the toast.
     */
    const displaySuccessToast = (name: string) =>
        invokeSuccessToast(t('common.success.title'), t('siteDetails.edit.success.description', { name }));

    const onValidate = handleSubmit((data) => {
        if (company) {
            setIsRequestPending(true);
            providers.sitesProvider
                .editSite(company.id, {
                    id: editingSite.id,
                    name: data.name,
                    type: data.type,
                    communities: data.communities,
                })
                .then(() => {
                    displaySuccessToast(data.name);
                    onSubmitted();
                })
                .catch((err) => {
                    if (err instanceof SiteNameAlreadyExistsException) {
                        setError('name', { message: t('sites.edit.error.name.alreadyExists') });
                    } else {
                        handleError(err);
                    }
                })
                .finally(() => setIsRequestPending(false));
        }
    });

    return (
        <>
            <Modal.Body>
                <>
                    <div className={styles.grid}>
                        <FormControl isInvalid={errors.name !== undefined}>
                            <FormControl.Label>{t('sites.edit.name.label')}</FormControl.Label>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field: { onBlur, onChange, ref, value } }) => (
                                    <Input
                                        ref={ref}
                                        onBlur={onBlur}
                                        onChange={onChange}
                                        placeholder={t('sites.edit.name.placeholder')}
                                        value={value}
                                        variant="rounded"
                                    />
                                )}
                            />
                            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                {errors.name?.message}
                            </FormControl.ErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={errors.type !== undefined}>
                            <FormControl.Label>{t('sites.edit.type.label')}</FormControl.Label>
                            <Controller
                                control={control}
                                name="type"
                                render={({ field: { value, onChange } }) => (
                                    <SelectButton
                                        initialValue={value}
                                        label={options[value].label}
                                        onChange={(newType) => onChange(newType)}
                                        options={Object.values(options)}
                                    />
                                )}
                            />
                            <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                                {errors.type?.message}
                            </FormControl.ErrorMessage>
                        </FormControl>
                    </div>
                    <Box height={4} />
                    <FormControl isInvalid={errors.communities !== undefined}>
                        <Controller
                            control={control}
                            name="communities"
                            render={({ field }) => (
                                <>
                                    <FormControl.Label>
                                        {t('sites.edit.communities.label', {
                                            count: (field.value ?? []).length,
                                        })}
                                    </FormControl.Label>
                                    <ChipSelector
                                        chipList={communities}
                                        onChange={field.onChange}
                                        selectedChips={field.value || []}
                                    />
                                </>
                            )}
                        />
                        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                            {(errors.communities as unknown as FieldError)?.message}
                        </FormControl.ErrorMessage>
                    </FormControl>
                </>
            </Modal.Body>
            <Modal.Footer>
                <GradientButton
                    fontSize="sm"
                    isLoading={isRequestPending}
                    label={t('siteDetails.edit.validate')}
                    onClick={onValidate}
                />
            </Modal.Footer>
        </>
    );
};

export default EditSiteModalContent;
