export interface Socket {
    id: string;
    name: string;
    siteName?: string;
    description?: string;
    chargeStatus: SocketChargeStatus;
    isConnected: boolean;
    readable: string;
}

export interface EditSocketPayload extends Pick<Socket, 'id' | 'name' | 'description'> {}

export enum SocketChargeStatus {
    CHARGING = 'CHARGING',
    NOT_CHARGING = 'NOT_CHARGING',
}
