export interface AddUserRolesPayload {
    communities: CommunityId[] | undefined;
    isManager: boolean;
}

export interface AddUserPayload {
    communities: CommunityId[] | undefined;
    email: string;
    firstName: string;
    isManager: boolean;
    lastName: string;
    phoneNumber: string;
}

export interface DeleteUserPayload extends AddUserPayload {
    id: string;
}

export interface EditUserPayload extends AddUserPayload {
    id: string;
}

export interface ExistingUser {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
}

export class UserAlreadyExistsException {
    existingUser: ExistingUser;

    constructor(existingUser: ExistingUser) {
        this.existingUser = existingUser;
    }
}
