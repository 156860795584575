import { ArrowBackIcon, ArrowUpIcon, Fab, HStack, Heading, Icon, Row, Text, useMediaQuery } from 'native-base';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { SCREENSIZE } from '../../global';
import { getCompany } from '../../store/auth/auth';
import { isGetCompanyPayload } from '../../store/auth/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { menuState, toggle } from '../../store/menu/menu';
import { editProfile, getProfileData, profileState } from '../../store/profile/profile';
import ButtonIcon from '../Button/ButtonIcon';
import Cookie from '../Cookie/Cookie';
import HeaderBar from '../HeaderBar/HeaderBar';
import Menu from '../Menu/Menu';
import SelectLanguage from '../Select/SelectLanguage';
import Support from '../Support/Support';
import styles from './PageLayout.module.css';

/**
 * Base page layout (including the lateral menu and the main content of the page)
 */
const PageLayout: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [companies, setCompanies] = useState<{ id: CompanyId; name: string }[]>([]);
    const display = useAppSelector(menuState);
    const profile = useAppSelector(profileState);
    const [isScrolling, setIsScrolling] = useState(false);
    const hasMounted = useRef(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    window.onscroll = () => {
        if (!isScrolling && window.scrollY > 300) {
            setIsScrolling(true);
        }
        if (window.scrollY === 0) {
            setIsScrolling(false);
        }
    };

    const onCookieAccepted = async () => dispatch(editProfile({ cookieAccepted: true }));

    /**
     * Effect executed when the first Logged-in page is displayed (as the routing is done inside `Outlet`).
     * So, whatever the path the user has taken to be in a Logged-in page (from loggin page or already logged), this effect will have been executed
     */
    useEffect(() => {
        if (!hasMounted.current) {
            hasMounted.current = true;
            dispatch(getProfileData());
            dispatch(getCompany()).then(({ payload }) => {
                if (isGetCompanyPayload(payload)) {
                    setCompanies(payload.companies);
                }
            });
        }
    }, []);

    return (
        <div className={styles.container}>
            <Menu companies={companies} />
            <div className={styles.content}>
                <Outlet />
            </div>
            <div
                aria-label="overlay"
                id={styles.overlay}
                onClick={() => {
                    dispatch(toggle());
                }}
                onKeyUp={() => {
                    dispatch(toggle());
                }}
                role="button"
                style={{ visibility: display.show ? 'visible' : 'hidden' }}
                tabIndex={0}
            />
            <Fab
                bottom="7.8%"
                display={isScrolling ? 'inline' : 'none'}
                icon={
                    <Icon color="gray.50" size={4}>
                        <ArrowUpIcon />
                    </Icon>
                }
                onPress={() => {
                    scrollToTop();
                }}
                position="fixed"
                shadow={2}
                size="sm"
            />
            <Cookie onSubmit={onCookieAccepted} open={!profile.cookieAccepted} />
            <Support />
        </div>
    );
};

export const PageLayoutHeader = ({ title, backUrl }: { title?: ReactNode; backUrl?: string }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });
    const display = useAppSelector(menuState);

    return (
        <Row alignItems="center" mb={4}>
            {backUrl ? (
                <ButtonIcon
                    icon={<ArrowBackIcon size="6" />}
                    onPress={() => navigate(backUrl)}
                    testID="back-button"
                    variant="unstyled"
                />
            ) : null}

            {title && !isSmallScreen ? (
                <HStack width="full">
                    <Heading key={1} flex={1} fontWeight={600} size="lg">
                        {title}
                    </Heading>
                    <SelectLanguage />
                </HStack>
            ) : title && isSmallScreen && !display.show ? (
                <HeaderBar title={title} />
            ) : (
                !isSmallScreen && (
                    <HStack alignItems="center" width="full">
                        <Text flex={1}>{t('common.back-to-list')}</Text>
                        <SelectLanguage mr={10} />
                    </HStack>
                )
            )}
        </Row>
    );
};

export default PageLayout;
