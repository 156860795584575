import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    FormControl,
    HStack,
    Input,
    Modal,
    Stack,
    Switch,
    WarningOutlineIcon,
    useMediaQuery,
    Radio,
    Box,
    Tooltip,
} from 'native-base';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import GradientButton from '../../../components/Button/GradientButton/GradientButton';
import OutlinedButton from '../../../components/Button/OutlinedButton';
import handleError from '../../../components/ErrorToast/ErrorToast';
import CardIcon from '../../../components/Icons/CardIcon';
import LockIcon from '../../../components/Icons/LockIcon';
import { invokeSuccessToast } from '../../../components/Toast/Toast';
import { RC_PUBLIC_COMMUNITY, SCREENSIZE } from '../../../global';
import useRemoteConfig from '../../../hooks/use-remote-config';
import providers from '../../../providers';
import { companySelect } from '../../../store/auth/auth';
import { useAppSelector } from '../../../store/hooks';
import { Community, CommunityUsageMode } from '../../../types';
import { NameAlreadyExistsException } from '../../base/types';

/**
 * Form used to edit a site
 */
const AddOrEditCommunityModalContent = ({
    editingCommunity,
    onSubmitted,
}: {
    editingCommunity?: Community; // The Community we want to edit
    onSubmitted: VoidFunction;
}) => {
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);
    const navigate = useNavigate();
    const [isRequestPending, setIsRequestPending] = useState<boolean>(false);
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    const featureEnable = useRemoteConfig(RC_PUBLIC_COMMUNITY).asBoolean();

    const formSchema = yup.object({
        name: yup.string().required(t('communities.edit.error.name.required')),
        usageMode: yup
            .number()
            .oneOf([CommunityUsageMode.ALL, CommunityUsageMode.PERSONAL, CommunityUsageMode.PROFESSIONAL])
            .default(CommunityUsageMode.ALL),
        paymentEnabled: yup.boolean().default(true),
        isDefault: yup.boolean().default(false),
        isPublic: yup.boolean().default(false),
    });

    const mapCommunityDetailsToForm = (details: Community): yup.InferType<typeof formSchema> => ({
        name: details.name,
        usageMode: details.usageMode,
        paymentEnabled: details.paymentEnabled,
        isDefault: details.isDefault,
        isPublic: details.isPublic,
    });

    const {
        control,
        formState: { errors },
        handleSubmit,
        setError,
        setValue,
        getValues,
    } = useForm<yup.InferType<typeof formSchema>>({
        reValidateMode: 'onSubmit',
        resolver: yupResolver(formSchema),
        defaultValues: editingCommunity
            ? mapCommunityDetailsToForm(editingCommunity)
            : { name: '', usageMode: CommunityUsageMode.ALL, paymentEnabled: true, isDefault: false, isPublic: false },
    });

    const [formState, setFormState] = useState<any>({
        isPublic: getValues('isPublic'),
    });

    const handleSwitchChange = (value: boolean) => {
        setFormState((prevState: any) => ({
            ...prevState,
            isPublic: value,
        }));
    };

    const usageModeOptions = [
        { label: t('communities.edit.usageMode.options.all'), value: CommunityUsageMode.ALL, isDisabled: false },
        {
            label: t('communities.edit.usageMode.options.personal'),
            value: CommunityUsageMode.PERSONAL,
            isDisabled: formState.isPublic,
        },
        {
            label: t('communities.edit.usageMode.options.professional'),
            value: CommunityUsageMode.PROFESSIONAL,
            isDisabled: formState.isPublic,
        },
    ];

    /**
     * Displays a toast about the success of form submission.
     * @param name the site name displayed in the toast.
     */
    const displaySuccessToast = (name: string) =>
        invokeSuccessToast(
            t('common.success.title'),
            editingCommunity
                ? t('communityDetails.edit.success.description', { name })
                : t('communityDetails.add.success.description', { name }),
        );

    const onValidate = handleSubmit(async (data) => {
        if (company) {
            try {
                setIsRequestPending(true);
                if (editingCommunity) {
                    await providers.communitiesProvider.editCommunity(company.id, {
                        id: editingCommunity.id,
                        name: data.name,
                        usageMode: data.usageMode,
                        paymentEnabled: data.paymentEnabled,
                        isDefault: data.isDefault,
                        isPublic: data.isPublic,
                    });
                } else {
                    await providers.communitiesProvider.addCommunity(company.id, {
                        name: data.name,
                        usageMode: data.usageMode,
                        paymentEnabled: data.paymentEnabled,
                        isDefault: data.isDefault,
                        isPublic: data.isPublic,
                    });
                }
                displaySuccessToast(data.name);
                onSubmitted();
            } catch (err) {
                if (err instanceof NameAlreadyExistsException) {
                    setError('name', { message: t('communities.edit.error.name.alreadyExists') });
                } else {
                    handleError(err as Error);
                }
            } finally {
                setIsRequestPending(false);
            }
        }
    });

    const onDelete = async () => {
        try {
            if (company && editingCommunity) {
                setIsRequestPending(true);
                await providers.communitiesProvider.deleteCommunity(company.id, editingCommunity.id);
                invokeSuccessToast(
                    t('common.success.title'),
                    t('communityDetails.delete.success', { name: editingCommunity.name }),
                );
                navigate('/communities');
            }
        } catch (error) {
            handleError(error as Error);
        } finally {
            setIsRequestPending(false);
        }
    };

    return (
        <>
            <Modal.Body>
                <>
                    <FormControl isInvalid={errors.name !== undefined}>
                        <FormControl.Label>{t('communities.edit.name.label')}</FormControl.Label>
                        <Controller
                            control={control}
                            name="name"
                            render={({ field: { onBlur, onChange, ref, value } }) => (
                                <Input
                                    ref={ref}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                    placeholder={t('communities.edit.name.placeholder')}
                                    value={value}
                                    variant="rounded"
                                    width={isSmallScreen ? 'full' : '50%'}
                                />
                            )}
                        />
                        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                            {errors.name?.message}
                        </FormControl.ErrorMessage>
                    </FormControl>

                    {featureEnable && (
                        <FormControl>
                            <FormControl.Label>{t('communities.edit.isPublic.label')}</FormControl.Label>
                            <Controller
                                control={control}
                                name="isPublic"
                                render={({ field: { onChange, ref, value } }) => (
                                    <HStack alignItems="center" px={2} space={3}>
                                        <LockIcon lock={!value} />
                                        <Switch
                                            ref={ref}
                                            isChecked={value}
                                            onValueChange={(_value) => {
                                                onChange(_value);
                                                handleSwitchChange(_value);
                                            }}
                                        />
                                    </HStack>
                                )}
                            />
                        </FormControl>
                    )}

                    <FormControl>
                        <FormControl.Label>{t('communities.edit.usageMode.label')}</FormControl.Label>
                        <Controller
                            control={control}
                            name="usageMode"
                            render={({ field: { onChange, value } }) => (
                                <Radio.Group
                                    accessibilityLabel="pick a usage mode"
                                    defaultValue={value.toString()}
                                    name="usageMode"
                                    onChange={(_value) => onChange(_value)}
                                    value={
                                        !getValues('isPublic') ? value.toString() : CommunityUsageMode.ALL.toString()
                                    }
                                >
                                    <Stack
                                        flexDirection={isSmallScreen ? 'column' : 'row'}
                                        justifyContent="space-between"
                                        my={1}
                                        px={2}
                                        space={2}
                                        width={isSmallScreen ? 'full' : '50%'}
                                    >
                                        {usageModeOptions.map((option) => (
                                            <Box key={option.value} mr={5}>
                                                {option.isDisabled ? (
                                                    <Tooltip
                                                        label={t('communities.edit.usageMode.tooltip')}
                                                        openDelay={500}
                                                    >
                                                        <Radio
                                                            isDisabled={option.isDisabled}
                                                            size={isSmallScreen ? 'sm' : 'md'}
                                                            value={option.value.toString()}
                                                        >
                                                            {option.label}
                                                        </Radio>
                                                    </Tooltip>
                                                ) : (
                                                    <Radio
                                                        isDisabled={option.isDisabled}
                                                        size={isSmallScreen ? 'sm' : 'md'}
                                                        value={option.value.toString()}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                )}
                                            </Box>
                                        ))}
                                    </Stack>
                                </Radio.Group>
                            )}
                        />
                    </FormControl>

                    <FormControl>
                        <FormControl.Label>{t('communities.edit.paymentEnabled.label')}</FormControl.Label>
                        <Controller
                            control={control}
                            name="paymentEnabled"
                            render={({ field: { onChange, ref, value } }) => (
                                <HStack alignItems="center" px={2} space={3}>
                                    <CardIcon paymentEnabled={value} />
                                    <Switch ref={ref} isChecked={value} onValueChange={onChange} />
                                </HStack>
                            )}
                        />
                    </FormControl>
                </>
            </Modal.Body>
            <Modal.Footer>
                <Stack
                    flexDirection={isSmallScreen ? 'column' : 'row'}
                    justifyContent="space-between"
                    space={4}
                    w="full"
                >
                    {!editingCommunity ? null : (
                        <Button.Group>
                            <OutlinedButton
                                disabled={editingCommunity?.isDefault}
                                label={t('communityDetails.edit.setAsMaster')}
                                onPress={() => {
                                    setValue('isDefault', true);
                                    if (editingCommunity) {
                                        onValidate();
                                    }
                                }}
                            />
                            <Button colorScheme="danger" isDisabled={editingCommunity?.isDefault} onPress={onDelete}>
                                {t('common.delete')}
                            </Button>
                        </Button.Group>
                    )}
                    <GradientButton
                        fontSize="sm"
                        isLoading={isRequestPending}
                        label={editingCommunity ? t('communityDetails.edit.validate') : t('communities.add.validate')}
                        onClick={onValidate}
                    />
                </Stack>
            </Modal.Footer>
        </>
    );
};

export default AddOrEditCommunityModalContent;
