import { t } from 'i18next';
import { ITextProps, Select, useMediaQuery } from 'native-base';
import React, { useEffect } from 'react';
import { SCREENSIZE } from '../../global';
import i18n from '../../i18n/i18next';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { profileState, editProfile } from '../../store/profile/profile';
import { LanguageCode } from '../../types';

export interface SelectLanguageProps {
    mr?: ITextProps['m'];
}

const SelectLanguage = ({ mr }: SelectLanguageProps) => {
    const profile = useAppSelector(profileState);
    const dispatch = useAppDispatch();
    const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language);
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    useEffect(() => {
        if (profile.language) {
            setSelectedLanguage(profile.language);
        }
    }, [profile]);

    const handleLanguageChange = (language: string) => {
        setSelectedLanguage(language);
        dispatch(editProfile({ language }));
    };

    const getSelectItemLabel = (lang: any) => `${t(`global.flag.${lang}`)} ${t(`global.language.${lang}`)}`;

    return (
        <Select
            aria-label={t('common.language.title')}
            backgroundColor={['#2f2e48', '#1a1d2e']}
            borderRadius={5}
            borderWidth={1}
            color="white"
            maxWidth="180px"
            mr={mr}
            onValueChange={handleLanguageChange}
            placeholder={t('common.language.title')}
            px="10px"
            selectedValue={selectedLanguage}
            style={{ backgroundColor: isSmallScreen ? '#2f2e48' : '#1a1d2e' }}
        >
            <Select.Item label={getSelectItemLabel(LanguageCode.EN)} value={LanguageCode.EN} />
            <Select.Item label={getSelectItemLabel(LanguageCode.FR)} value={LanguageCode.FR} />
            <Select.Item label={getSelectItemLabel(LanguageCode.NL)} value={LanguageCode.NL} />
        </Select>
    );
};

export default SelectLanguage;
