export interface LoginState {
    step: LoginSteps;
    loading: boolean;
    errorMsg?: string;
    phoneNumber?: string;
}

export enum LoginSteps {
    Otp,
    PhoneNumber,
}

export enum Roles {
    CLIENT = 'CLIENT',
    MANAGER = 'MANAGER',
    INSTALLER = 'INSTALLER',
}

export enum LoginErrors {
    NO_MANAGER_ROLE = 'NO_MANAGER_ROLE',
    INCORRECT_CODE = 'INCORRECT_CODE',
    NO_ACCESS = 'NO_ACCESS',
    NO_COMPANY = 'NO_COMPANY',
}
