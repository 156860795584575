import { Button, IButtonProps } from 'native-base';
import React, { FC } from 'react';

interface OutlinedButtonProps extends IButtonProps {
    label: string;
    disabled?: boolean;
    onPress: () => void;
}

const OutlinedButton: FC<OutlinedButtonProps> = ({ label, onPress, disabled = false, ...props }) => (
    <Button
        {...props}
        _text={{ color: disabled ? 'gray.500' : 'white' }}
        aria-label={label}
        color={disabled ? 'gray.500' : undefined}
        colorScheme="secondary"
        fontSize="sm"
        isDisabled={disabled}
        onPress={onPress}
        px={5}
        variant="outline"
    >
        {label}
    </Button>
);

export default OutlinedButton;
