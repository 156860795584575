import { isDefined } from '@sparklin/react.api.utils.is-defined';
import { Icon, Text } from 'native-base';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import getStatusLabel from '../../pages/Users/utils';
import BaseCard from './BaseCard';
import { ReactComponent as AdminIcon } from '../../assets/icons/admin.svg';
import userImg from '../../assets/images/user.png';

interface UserCardProps {
    chargesCount?: number;
    communities?: string[];
    firstName: string;
    isManager?: boolean;
    lastName: string;
    onTouch?: VoidFunction;
    status?: 'ACTIVE';
}

const UserCard: FC<UserCardProps> = ({
    chargesCount,
    communities,
    firstName,
    isManager,
    lastName,
    onTouch,
    status = 'ACTIVE',
}) => {
    const { t } = useTranslation();

    const description = [];
    if (communities) {
        if (communities.length === 1) {
            description.push(communities[0]);
        } else {
            description.push(t('users.card.communitiesCount', { count: communities.length }));
        }
    }
    if (isManager) {
        description.push(t('users.card.manager'));
    }

    return (
        <BaseCard
            badge={getStatusLabel(t, status)}
            icon={
                isManager ? (
                    <Icon>
                        <AdminIcon />
                    </Icon>
                ) : undefined
            }
            imageSrc={userImg}
            onTouch={onTouch}
            title={`${firstName} ${lastName}`}
            isPressable
        >
            <Text fontSize="xs">{description.join(' • ')}</Text>
            {isDefined(chargesCount) ? (
                <Text fontSize="xs">{t('users.card.chargesCount', { count: chargesCount })}</Text>
            ) : undefined}
        </BaseCard>
    );
};

export default UserCard;
