import { AxiosError } from 'axios';

export class NetworkException<T> extends Error {
    status?: number;

    message: string;

    data: T;

    constructor(status: number | undefined, message: string, data: T) {
        super(message);
        this.status = status;
        this.message = message;
        this.data = data;
    }
}

export const isAxiosError = <T>(error: any): error is AxiosError<T> => (error as AxiosError).isAxiosError !== undefined;
export const isNetworkException = <T>(error: any): error is NetworkException<T> => error.message !== undefined;
