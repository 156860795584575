import { Button, Text } from 'native-base';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface MonthSelectorProps {
    month: number;
    setMonth: (year: number) => void;
    year: number;
}

const MonthSelector: FC<MonthSelectorProps> = ({ month, setMonth, year }) => {
    const { i18n } = useTranslation();

    const checkIfSelectableMonth = (checkedMonth: number) => {
        if (year === new Date().getFullYear()) {
            return checkedMonth <= new Date().getMonth();
        }

        return true;
    };

    const monthsForLocale = (localeName = i18n.language, monthFormat: Intl.DateTimeFormatOptions['month'] = 'long') => {
        const { format } = new Intl.DateTimeFormat(localeName, { month: monthFormat });

        return Array.from(Array(12).keys()).map((m) => format(new Date(Date.UTC(2021, m % 12))));
    };

    const monthsLabels = useMemo(() => monthsForLocale(i18n.language), [i18n.language]);

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: '5px 2px' }}>
            {monthsLabels.map((monthLabel, index) => (
                <Button
                    key={monthLabel}
                    _pressed={{ borderColor: 'gray.500' }}
                    borderRadius="lg"
                    disabled={month === index || !checkIfSelectableMonth(index)}
                    h={10}
                    mx={2}
                    my={2}
                    onPress={() => setMonth(index)}
                    p={0}
                    variant={month === index ? 'solid' : 'ghost'}
                >
                    <Text
                        color={checkIfSelectableMonth(index) ? 'white' : 'gray.300'}
                        fontSize="sm"
                        fontWeight={month === index ? 'semibold' : 'normal'}
                        textTransform="capitalize"
                    >
                        {monthLabel}
                    </Text>
                </Button>
            ))}
        </div>
    );
};

export default MonthSelector;
