import { isDefined } from '@sparklin/react.api.utils.is-defined';
import { Button, Container, HStack, Stack, Text, VStack, useMediaQuery } from 'native-base';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BaseBadge from '../../components/Badge/BaseBadge';
import EditButton from '../../components/Button/EditButton';
import FormModal from '../../components/FormModal/FormModal';
import CardIcon from '../../components/Icons/CardIcon';
import LockIcon from '../../components/Icons/LockIcon';
import { RC_PUBLIC_COMMUNITY, SCREENSIZE } from '../../global';
import useRemoteConfig from '../../hooks/use-remote-config';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import { getUsageModeLabel } from '../../utils/get-localized-label';
import BaseDetails from '../base/BaseDetails';
import EditCommunityModalContent from '../Communities/AddOrEditCommunityModalContent/AddOrEditCommunityModalContent';
import SiteList from '../Sites/SiteList';
import UserList from '../Users/UserList';
import { CommunityDetails as CommunityDetailsType } from './types';
import communityImg from '../../assets/images/community.svg';

const CommunityDetails = () => {
    const { t } = useTranslation();
    const { id: communityId } = useParams<{ id: string }>() as { id: string };
    const company = useAppSelector(companySelect);
    const [community, setCommunity] = useState<CommunityDetailsType | undefined>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | undefined>();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [showSite, setShowSite] = useState(true);
    const [showUsers, setShowUsers] = useState(false);
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });
    const featureEnable = useRemoteConfig(RC_PUBLIC_COMMUNITY).asBoolean();

    const refreshSite = () => {
        if (isDefined(company)) {
            setLoading(true);
            providers.communitiesProvider
                .getCommunityDetails(company.id, parseInt(communityId, 10))
                .then(setCommunity)
                .catch(setError)
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        refreshSite();
    }, [company]);

    const headerChildren = (
        <VStack space={2}>
            <Text>{getUsageModeLabel(t, community?.usageMode!)}</Text>
            {featureEnable && (
                <HStack space={3}>
                    <LockIcon lock={!community?.isPublic} />
                    <Text fontSize={['12px', null, null]}>
                        {community?.isPublic
                            ? t('communityDetails.header.publicEnabled')
                            : t('communityDetails.header.publicDisabled')}
                    </Text>
                </HStack>
            )}

            <HStack space={3}>
                <CardIcon paymentEnabled={community?.paymentEnabled} />
                <Text fontSize={['12px', null, null]}>
                    {community?.paymentEnabled
                        ? t('communityDetails.header.paymentEnabled')
                        : t('communityDetails.header.paymentDisabled')}
                </Text>
            </HStack>
        </VStack>
    );

    const badge = useMemo(
        () => (community?.isDefault ? <BaseBadge label={t('communities.card.default')} /> : null),
        [community],
    );

    const title =
        community &&
        (!isSmallScreen ? (
            <HStack alignItems="center" space={3}>
                <Text>{community.name}</Text>
                {badge}
            </HStack>
        ) : (
            <VStack w="full">
                <HStack justifyContent="flex-end">{badge}</HStack>
                <Text fontSize={16} mt={2}>
                    {community.name}
                </Text>
            </VStack>
        ));

    const changeRenderListSites = () => {
        setShowSite(true);
        setShowUsers(false);
    };

    const changeRenderListUsers = () => {
        setShowUsers(true);
        setShowSite(false);
    };

    return (
        <BaseDetails
            backUrl="/communities/"
            error={error}
            headerAction={
                <EditButton
                    label={t('communityDetails.edit.button')}
                    onPress={() => setEditModalOpen(true)}
                    variant={isSmallScreen ? 'unstyled' : 'outline'}
                />
            }
            headerChildren={headerChildren}
            imageSrc={communityImg}
            isLoading={loading}
            title={title}
        >
            {isSmallScreen ? (
                <Button.Group h="35px" justifyContent="end" mb={10} isAttached>
                    <Button
                        onPress={changeRenderListSites}
                        style={{ borderColor: 'rgba(255, 255, 255, 0.5)' }}
                        variant={showSite ? 'solid' : 'outline'}
                    >
                        <Text>{t('menu.sites')}</Text>
                    </Button>
                    <Button
                        onPress={changeRenderListUsers}
                        style={{ borderColor: 'rgba(255, 255, 255, 0.5)' }}
                        variant={showUsers ? 'solid' : 'outline'}
                    >
                        <Text>{t('menu.users')}</Text>
                    </Button>
                </Button.Group>
            ) : null}
            <HStack justifyContent="space-between">
                <Stack direction={['column', 'row']} flex={1} space={[0, 2]}>
                    <Container
                        flex={0.5}
                        maxW="100%"
                        style={{ display: !isSmallScreen ? 'flex' : showSite ? 'flex' : 'none' }}
                    >
                        <SiteList count={community?.sites.length || 0} list={community?.sites || []} withSearch />
                    </Container>
                    <Container
                        flex={0.5}
                        maxW="100%"
                        style={{ display: !isSmallScreen ? 'flex' : showUsers ? 'flex' : 'none' }}
                    >
                        <UserList count={community?.users.length || 0} list={community?.users || []} withSearch />
                    </Container>
                </Stack>
            </HStack>
            {community && (
                <FormModal
                    isOpen={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    testID="edit-community-modal"
                    title={t('communityDetails.edit.title')}
                >
                    <EditCommunityModalContent
                        editingCommunity={community}
                        onSubmitted={() => {
                            refreshSite();
                            setEditModalOpen(false);
                        }}
                    />
                </FormModal>
            )}
        </BaseDetails>
    );
};

export default CommunityDetails;
