import { Text } from 'native-base';
import React from 'react';

interface DurationProps {
    /**
     * Duration expressed in minutes
     */
    duration: number;
}

const computeDurationString = (minutes: number) => {
    const hours = Math.trunc(minutes / 60);
    if (hours > 0) {
        return `${hours} h ${(minutes % 60).toString().padStart(2, '0')}`;
    }

    return `${minutes} min`;
};

const Duration = ({ duration }: DurationProps) => <Text>{computeDurationString(duration)}</Text>;

export default Duration;
