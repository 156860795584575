import { isDefined } from '@sparklin/react.api.utils.is-defined';
import { Center, Spinner, VStack } from 'native-base';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import providers from '../../providers';

const SetupPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const code = location?.pathname.split('/').at(2);

    useEffect(() => {
        if (isDefined(code)) {
            providers.socketsProvider
                .getSocket(code)
                .then(() => {
                    navigate(`/socket/${code}`);
                })
                .catch(() => window.location.replace('https://setup.sparklin.io/'));
        }
    }, [code]);

    return (
        <Center height="100vh">
            <VStack alignItems="center" space={8}>
                <Spinner size="lg" />
            </VStack>
        </Center>
    );
};

export default SetupPage;
