import { IPaginationOptions, Pagination } from '@sparklin/react.api.hooks.use-pagination';
import { ExportType } from '../../pages/Sites/types';
import { UserCharge } from '../../pages/UserDetails/types';
import BaseProvider from '../base.provider';
import { GetChargesForUserWSOutput, GetExpensesFileInput } from './types';

export default class ChargeProvider extends BaseProvider {
    getChargesForUser = (
        companyId: CompanyId,
        userId: string,
        options: IPaginationOptions,
    ): Promise<Pagination<UserCharge>> =>
        this.http.get<GetChargesForUserWSOutput>(`/api/v1/companies/${companyId}/users/${userId}/charges`, options);

    getExpensesFileForUser = async (
        companyId: CompanyId,
        userId: string,
        month: number,
        year: number,
        exportType: ExportType = ExportType.EXPENSE,
    ): Promise<{ data: Blob; fileName: string | undefined }> =>
        this.http.postBlob<GetExpensesFileInput>(`/api/v1/companies/${companyId}/users/${userId}/expenses`, {
            month: `${year}-${month}`,
            exportType,
        });

    getExpensesFileForCompany = async (
        companyId: CompanyId,
        month: number,
        year: number,
        exportType: ExportType = ExportType.EXPENSE,
    ): Promise<{ data: Blob; fileName: string | undefined }> =>
        this.http.postBlob<GetExpensesFileInput>(`/api/v1/companies/${companyId}/expenses`, {
            month: `${year}-${month}`,
            exportType,
        });
}
