import * as Sentry from '@sentry/browser';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Toast from '../Toast/Toast';

/**
 * A basic toast to display a simple message about an error.
 */
const ErrorToast = () => {
    const { t } = useTranslation();

    return <Toast status="error" title={t('common.error.toast.message')} />;
};

/**
 * This is the method used to display a toast about an error and log this error to Sentry.
 */
const handleError = (e: Error) => {
    Sentry.captureException(e);
    toast(<ErrorToast />);
};

export default handleError;
