import { HStack, VStack, useMediaQuery } from 'native-base';
import React, { useEffect, useState } from 'react';
import SearchInput from '../../components/Input/SearchInput';
import { SCREENSIZE } from '../../global';
import { SearchSchema } from '../../utils/search-schema';
import BaseEntityList from './BaseEntityList';
import { BaseListProps } from './types';

interface PageListProps<T> extends BaseListProps<T> {
    withSearch?: boolean;
    searchSchema?: SearchSchema;
    title: string;
    list: T[];
    button?: JSX.Element;
    modal?: JSX.Element;
    withbutton?: boolean;
    renderChild: (child: T) => JSX.Element;
}

const PageList = <T,>({
    list,
    renderChild,
    title,
    withSearch = false,
    searchSchema,
    button,
    modal,
    withbutton,
}: PageListProps<T>) => {
    const [searchedList, setSearchedList] = useState<T[]>(list);
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    useEffect(() => {
        setSearchedList(list);
    }, [list, setSearchedList]);

    return (
        <>
            <VStack mb={5} w="full" zIndex="-1">
                <HStack justifyContent="space-between" space={4}>
                    {withSearch && searchSchema ? (
                        <SearchInput
                            data={list}
                            onChange={setSearchedList}
                            schema={searchSchema}
                            w={withbutton && isSmallScreen ? '80%' : isSmallScreen ? '100%' : '200px'}
                        />
                    ) : undefined}
                    {withbutton ? button : undefined}
                </HStack>
                <BaseEntityList title={title}>{searchedList.map(renderChild)}</BaseEntityList>
            </VStack>
            {modal}
        </>
    );
};

export default PageList;
