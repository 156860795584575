import { Box, Modal, Text } from 'native-base';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExportType } from '../../pages/Sites/types';
import { UserDetails } from '../../pages/UserDetails/types';
import { Company } from '../../pages/Users/types';
import providers from '../../providers';
import { isNetworkException } from '../../providers/types';
import GradientButton from '../Button/GradientButton/GradientButton';
import handleError from '../ErrorToast/ErrorToast';
import MonthSelector from '../MonthSelector/MonthSelector';
import { invokeInfoToast, invokeSuccessToast } from '../Toast/Toast';
import YearSelector from '../YearSelector/YearSelector';

interface ExpenseUsersMonthSelectionCompanyProps {
    company: Company;
    exportType: ExportType;
    closeModal: () => void;
}
interface ExpenseUsersMonthSelectionUserProps {
    company: Company;
    user: UserDetails;
    exportType: ExportType;
    closeModal: () => void;
}

type ExpenseUsersMonthSelectionProps = ExpenseUsersMonthSelectionCompanyProps | ExpenseUsersMonthSelectionUserProps;

interface DefaultConstants {
    fileName: string;
    subtitle: string;
    toast: {
        title: string;
        description: string;
    };
}

const ExpenseUsersMonthSelection = (props: ExpenseUsersMonthSelectionProps) => {
    const { closeModal, exportType = ExportType.EXPENSE } = props;

    const [month, setMonth] = useState<number>(new Date().getMonth());
    const [year, setYear] = useState<number>(new Date().getFullYear());
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const downloadedFileHref = useRef<HTMLAnchorElement>(null);
    const i18nType = exportType === ExportType.EXPENSE ? 'expense' : 'charge';
    const defaults: DefaultConstants = {
        fileName: t(`expenses.${i18nType}.fileName`, { month, year }),
        toast: {
            title: t(`expenses.${i18nType}.noExpenses.title`),
            description: t(`expenses.${i18nType}.noExpenses.description`),
        },
        subtitle: t(`expenses.${i18nType}.subtitle`),
    };

    const onValidate = async () => {
        try {
            setIsLoading(true);
            /* eslint-disable react/destructuring-assignment */
            const { data: blob, fileName } =
                'user' in props
                    ? await providers.chargesProvider.getExpensesFileForUser(
                          props.company.id,
                          props.user.id,
                          month + 1,
                          year,
                          exportType,
                      )
                    : await providers.chargesProvider.getExpensesFileForCompany(
                          props.company.id,
                          month + 1,
                          year,
                          exportType,
                      );
            /* eslint-enable react/destructuring-assignment */
            const href = window.URL.createObjectURL(blob);
            const a = downloadedFileHref.current;
            if (a) {
                a.download = fileName ?? defaults.fileName;
                a.href = href;
                a.click();
                a.href = '';
                invokeSuccessToast(t('expenses.success.title'), t('expenses.success.description'));
                closeModal();
            } else {
                throw new Error();
            }
        } catch (e) {
            if (isNetworkException(e) && e.status === 204) {
                // No suitable charges for this month.
                // We display an info toast.
                invokeInfoToast(defaults.toast.title, defaults.toast.description);
            } else if (e instanceof Error) {
                handleError(e);
            } else {
                console.error(e);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Modal.Body>
                <Text mb={4} w="full">
                    {defaults.subtitle}
                </Text>
                <YearSelector setYear={setYear} year={year} />
                <Box bg="gray.400" h="1px" mb={6} w="full" />
                <Box my={4}>
                    <MonthSelector month={month} setMonth={setMonth} year={year} />
                </Box>
            </Modal.Body>
            <Modal.Footer>
                <GradientButton
                    fontSize="sm"
                    isLoading={isLoading}
                    label={t('expenses.validate')}
                    onClick={onValidate}
                />
            </Modal.Footer>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/control-has-associated-label,jsx-a11y/anchor-is-valid */}
            <a ref={downloadedFileHref} />
        </>
    );
};

export default ExpenseUsersMonthSelection;
