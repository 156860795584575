import { t } from 'i18next';
import { Modal, Text, Button } from 'native-base';
import React from 'react';
import { disconnectThunk } from '../../store/auth/auth';
import { useAppDispatch } from '../../store/hooks';
import FormModal from './FormModal';

interface LogoutModalProps {
    setIsOpen: VoidFunction;
}

const LogoutModal = ({ setIsOpen }: LogoutModalProps) => {
    const dispatch = useAppDispatch();

    return (
        <FormModal onClose={setIsOpen} testID="logout-user-modal" title={t('logoutModal.title')} isOpen>
            <Modal.Body>
                <Text fontSize={16}>{t('logoutModal.question')}</Text>
            </Modal.Body>
            <Modal.Footer flexDir="row" justifyContent="flex-end">
                <Button
                    borderColor="white"
                    borderRadius={10}
                    borderWidth={1}
                    mr={3}
                    onPress={setIsOpen}
                    variant="unstyled"
                >
                    {t('logoutModal.cancel')}
                </Button>
                <Button bg="red.600" borderRadius={10} onPress={() => dispatch(disconnectThunk())} variant="unstyled">
                    {t('logoutModal.logOut')}
                </Button>
            </Modal.Footer>
        </FormModal>
    );
};

export default LogoutModal;
