import { Worker, Index, IndexSearchResult, IndexOptions } from 'flexsearch';
import { t } from 'i18next';
import { SearchIcon, Box, Input, Pressable, CloseIcon } from 'native-base';
import React, { FC, useEffect, useRef, useState } from 'react';
import { SearchSchema } from '../../utils/search-schema';

interface SearchInputProps<T> {
    data: T[];
    schema: SearchSchema;
    placeholder?: string;
    w?: string;
    onChange: (data: T[]) => void;
}

const SearchInput: FC<SearchInputProps<any>> = ({ data, schema, onChange, placeholder = t('common.search'), w }) => {
    const hasMounted = useRef(false);
    const [value, setValue] = useState<string>('');

    const indexes = new Map();
    const dataset = new Map();

    schema.forEach((options) => {
        const hasOption = typeof options !== 'string';
        let field: string;
        let indexOptions: IndexOptions<string> = {
            tokenize: 'forward',
            optimize: true,
        };

        if (!hasOption) {
            field = options;
            // indexOptions.encode = 'extra';
        } else {
            field = options.field;
            indexOptions = { ...indexOptions, ...options };
        }
        const index =
            (hasOption && typeof options.encode === 'function') || process.env.JEST_WORKER_ID
                ? new Index(indexOptions)
                : new Worker(indexOptions);
        indexes.set(field, index);
    });

    useEffect(() => {
        if (hasMounted.current) {
            data?.forEach((item, key) => {
                indexes.forEach((index, field) => {
                    index.add(key, item[field]);
                    dataset.set(key, item);
                });
            });
        } else {
            hasMounted.current = true;
        }
    }, [data, indexes, dataset]);

    const handleChange = async (text: string) => {
        setValue(text);
        if (text.length) {
            const res: Promise<IndexSearchResult>[] = [];
            indexes.forEach((index) => res.push(index.search(text, { limit: 10 })));

            const result = await Promise.all(res);
            onChange(result.flat().map((key) => dataset.get(key)));
        } else {
            onChange(data);
        }
    };

    const reset = () => {
        setValue('');
        onChange(data);
    };

    return (
        <Box alignItems="center" flexDirection="row" w={w}>
            <Input
                InputLeftElement={<SearchIcon color="coolGray.600" mx={2} size={4} />}
                InputRightElement={
                    <Pressable onPress={reset}>
                        <CloseIcon color="coolGray.600" mx={2} size={4} />
                    </Pressable>
                }
                _focus={{ backgroundColor: 'secondary.900' }}
                _input={{ backgroundColor: 'secondary.900', color: 'white' }}
                backgroundColor="secondary.900"
                bg="white:alpha.10"
                flex={1}
                onChangeText={handleChange}
                placeholder={placeholder}
                value={value}
                variant="rounded"
            />
        </Box>
    );
};

export default SearchInput;
