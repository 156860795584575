import React from 'react';
import { SupportProvider } from '../../integrations/support/types';
import ZendeskWidget from '../../integrations/support/zendesk/ZendeskWidget';
import { useAppSelector } from '../../store/hooks';
import { profileState } from '../../store/profile/profile';

interface SupportProps {
    provider?: SupportProvider;
}
const Support: React.FC<SupportProps> = ({ provider = SupportProvider.ZENDESK }) => {
    const profile = useAppSelector(profileState);
    const { language } = profile;
    const prefill = { name: `${profile.firstName} ${profile.lastName}`, email: profile.email };

    switch (provider) {
        case SupportProvider.ZENDESK:
            return <ZendeskWidget language={language} prefill={prefill} />;
        default:
            return null;
    }
};

export default Support;
