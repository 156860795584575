import { Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteType } from '../../pages/Sites/types';
import getSiteTypeLabel from '../../pages/Sites/utils';
import BaseCard from './BaseCard';
import sitePrivate from '../../assets/images/site-private.svg';
import siteShared from '../../assets/images/site-shared.svg';

interface SiteCardProps {
    name: string;
    type: SiteType;
    communities?: string[];
    onTouch?: VoidFunction;
}

const SiteCard = ({ name, type, communities, onTouch }: SiteCardProps) => {
    const { t } = useTranslation();
    const imageSrc = type === 'PRIVATE' ? sitePrivate : siteShared;
    let description;
    if (communities) {
        if (communities.length === 1) {
            [description] = communities;
        } else {
            description = t('users.card.communitiesCount', { count: communities.length });
        }
    }

    return (
        <BaseCard imageSrc={imageSrc} onTouch={onTouch} title={name} isPressable>
            <Text fontSize="xs">{getSiteTypeLabel(t, type)}</Text>
            <Text fontSize="xs">{description}</Text>
        </BaseCard>
    );
};

export default SiteCard;
