import { NameAlreadyExistsException } from '../../pages/base/types';
import {
    AddCommunityPayload,
    AddCommunityWSInput,
    EditCommunityPayload,
} from '../../pages/Communities/AddOrEditCommunityModalContent/types';
import { CommunityDetails } from '../../pages/CommunityDetails/types';
import { Community } from '../../types';
import BaseProvider from '../base.provider';
import { EditCommunityWSInput, GetCommunitiesWSOutput, GetCommunityWSOutput } from './types';

export default class CommunityProvider extends BaseProvider {
    addCommunity = async (companyId: CompanyId, addCommunityPayload: AddCommunityPayload) =>
        this.http.post<void, AddCommunityWSInput>(`/api/v1/companies/${companyId}/communities`, addCommunityPayload);

    getCommunities = async (companyId: CompanyId): Promise<Community[]> =>
        (await this.http.get<GetCommunitiesWSOutput>(`/api/v1/companies/${companyId}/communities`)).communities.map(
            (community) => ({
                id: community.id,
                name: community.name,
                usageMode: community.usageMode,
                paymentEnabled: community.paymentEnabled,
                isDefault: community.isDefault,
                isPublic: community.isPublic,
            }),
        );

    getCommunityDetails = async (companyId: CompanyId, communityId: CommunityId): Promise<CommunityDetails> => {
        const response = await this.http.get<GetCommunityWSOutput>(
            `/api/v1/companies/${companyId}/communities/${communityId}`,
        );

        return response;
    };

    editCommunity = async (companyId: CompanyId, editCommunityPayload: EditCommunityPayload) =>
        this.http
            .patch<void, EditCommunityWSInput>(
                `/api/v1/companies/${companyId}/communities/${editCommunityPayload.id}`,
                editCommunityPayload,
            )
            .catch((err) => {
                if (err.status === 400 && err.data?.name === 'ALREADY_EXISTS') {
                    throw new NameAlreadyExistsException(editCommunityPayload.name);
                }

                throw err;
            });

    deleteCommunity = async (companyId: CompanyId, communityId: CommunityId) =>
        this.http.delete<void>(`/api/v1/companies/${companyId}/communities/${communityId}`);
}
