import { TFunction } from 'i18next';
import { CommunityUsageMode } from '../types';

export const getUsageModeLabel = (t: TFunction, mode: CommunityUsageMode) => {
    switch (mode) {
        case CommunityUsageMode.PROFESSIONAL:
            return t('communityDetails.header.usageMode.professional');
        case CommunityUsageMode.PERSONAL:
            return t('communityDetails.header.usageMode.personal');
        case CommunityUsageMode.ALL:
        default:
            return t('communityDetails.header.usageMode.all');
    }
};
