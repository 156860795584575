import { Center } from 'native-base';
import React from 'react';
import OtpInput, { InputProps } from 'react-otp-input';
import styles from './CustomOtpInput.module.css';

export interface CustomOtpInputProps {
    onChange: (value: ((prevState: string) => string) | string) => void;
    value: string;
    numInputs?: number;
    onSubmit?: () => void;
}

export const CustomOtpInput = ({ value, onChange, onSubmit = () => {}, numInputs = 6 }: CustomOtpInputProps) => {
    const handleChange = (v: string) => {
        onChange(v);
        if (v.length === numInputs) {
            onSubmit();
        }
    };

    return (
        <Center>
            <OtpInput
                inputStyle={styles.input}
                numInputs={numInputs}
                onChange={handleChange}
                renderInput={(props: InputProps) => <input {...props} />}
                value={value}
                shouldAutoFocus
            />
        </Center>
    );
};
