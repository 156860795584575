import httpService from '../services/http';
import AuthProvider from './auth/auth.provider';
import ChargeProvider from './charge/charge.provider';
import CommunityProvider from './community/community.provider';
import CompanyProvider from './company/company.provider';
import ProfileProvider from './profile/profile.provider';
import SiteProvider from './site/site.provider';
import SocketProvider from './socket/socket.provider';
import UserProvider from './user/user.provider';

const providers = {
    httpService,
    authProvider: new AuthProvider(),
    chargesProvider: new ChargeProvider(),
    communitiesProvider: new CommunityProvider(),
    companiesProvider: new CompanyProvider(),
    profileProvider: new ProfileProvider(),
    socketsProvider: new SocketProvider(),
    sitesProvider: new SiteProvider(),
    usersProvider: new UserProvider(),
};

export default providers;
