import { SiteDetails } from '../../pages/SiteDetails/types';
import { EditSitePayload, SiteNameAlreadyExistsException } from '../../pages/Sites/EditSiteModalContent/types';
import { Site, SiteType } from '../../pages/Sites/types';
import { SocketChargeStatus } from '../../pages/Sockets/types';
import BaseProvider from '../base.provider';
import { EditSiteWSInput, GetSitesWSOutput, GetSiteWSOutput } from './types';

export default class SiteProvider extends BaseProvider {
    getSites = async (companyId: CompanyId): Promise<Site[]> =>
        (await this.http.get<GetSitesWSOutput>(`/api/v1/companies/${companyId}/sites`)).sites.map((site) => ({
            id: site.id,
            name: site.name,
            type: site.type as SiteType,
            communities: site.communities.map((comm) => comm.name),
        }));

    getSiteDetails = async (companyId: CompanyId, siteId: SiteId): Promise<SiteDetails> => {
        const response = await this.http.get<GetSiteWSOutput>(`/api/v1/companies/${companyId}/sites/${siteId}`);

        return {
            ...response,
            type: response.type as SiteType,
            sockets: response.sockets.map((socket) => ({
                ...socket,
                chargeStatus: socket.chargeStatus as SocketChargeStatus,
            })),
        };
    };

    editSite = async (companyId: CompanyId, editSitePayload: EditSitePayload) =>
        this.http
            .put<void, EditSiteWSInput>(`/api/v1/companies/${companyId}/sites/${editSitePayload.id}`, editSitePayload)
            .catch((err) => {
                if (err.status === 400 && err.data?.name === 'ALREADY_EXISTS') {
                    throw new SiteNameAlreadyExistsException(editSitePayload.name);
                }

                throw err;
            });
}
