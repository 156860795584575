import { TFunction } from 'i18next';
import { Socket, SocketChargeStatus } from './types';

const getChargeStatusLabel = (t: TFunction, st: Socket['chargeStatus'], isConnected: Socket['isConnected']) => {
    if (!isConnected) {
        return t('sockets.card.chargeStatus.notConnected');
    }
    switch (st) {
        case SocketChargeStatus.CHARGING:
            return t('sockets.card.chargeStatus.CHARGING');
        case SocketChargeStatus.NOT_CHARGING:
        default:
            return t('sockets.card.chargeStatus.NOT_CHARGING');
    }
};

export default getChargeStatusLabel;
