import { Box, Center, Column, Heading, Row, Text } from 'native-base';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface DashboardTileProps {
    title: string;
    value?: string | number;
    subtitle?: string;
    proportion?: number;
}

const DashboardTile: React.FunctionComponent<DashboardTileProps> = ({ title, value, subtitle, proportion }) => {
    const { t } = useTranslation();

    return (
        <Box bg="secondary.900" borderRadius={10} m={[1, 10]} minH={15} p={[3, 6]} w={['40%', 80]}>
            <Column space={4}>
                <Heading fontSize={['12px', '16px']} size="sm" textAlign={['center', null]}>
                    {title}
                </Heading>
                <Center my={[2, 6]} width="100%">
                    <Column alignItems="center">
                        <Row alignItems="baseline">
                            <Heading size="2xl">{value ?? 0}</Heading>
                            {proportion ? (
                                <Heading fontWeight={400} size="sm">
                                    {t('dashboard.proportionFormat', { proportion })}
                                </Heading>
                            ) : null}
                        </Row>
                        <Text fontSize={[10, null]} mt={[2, null]} textAlign={['center', null]}>
                            {subtitle}
                        </Text>
                    </Column>
                </Center>
            </Column>
        </Box>
    );
};

export default DashboardTile;
