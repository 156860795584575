import { useEffect, useState } from 'react';

const useScript = (url: string, id?: string) => {
    const [state, setState] = useState({
        loaded: false,
        error: false,
    });
    const onScriptLoad = () => {
        setState({
            loaded: true,
            error: false,
        });
    };

    const onScriptError = () => {
        setState({
            loaded: true,
            error: true,
        });
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = url;
        script.async = true;
        if (id) script.id = id;
        script.addEventListener('load', onScriptLoad);
        script.addEventListener('error', onScriptError);

        document.body.appendChild(script);

        return () => {
            script.removeEventListener('load', onScriptLoad);
            script.removeEventListener('error', onScriptError);
            document.body.removeChild(script);
        };
    }, [url, id]);

    return state;
};

export default useScript;
