import { AddIcon } from 'native-base';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GradientButton from '../../components/Button/GradientButton/GradientButton';
import UserCard from '../../components/Cards/UserCard';
import FormModal from '../../components/FormModal/FormModal';
import { UserSchema } from '../../utils/search-schema';
import PageList from '../base/PageList';
import { BaseListProps } from '../base/types';
import AddOrEditUserModalContent from './AddUserModalContent/AddOrEditUserModalContent';
import { User } from './types';

interface UserListProps extends BaseListProps<User> {
    count: number;
    onAddOrEdit?: VoidFunction;
    withbutton?: boolean;
}

const UserList: FC<UserListProps> = ({ list, count, withSearch = false, onAddOrEdit, withbutton = false }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const List = PageList<User>;
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <List
            button={
                <GradientButton
                    fontSize="sm"
                    icon={<AddIcon />}
                    label={t('users.createUserBtn')}
                    onClick={() => setModalOpen(true)}
                />
            }
            list={list}
            modal={
                <FormModal
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    testID="add-user-modal"
                    title={t('users.add.title')}
                >
                    <AddOrEditUserModalContent
                        onSubmitted={() => {
                            if (onAddOrEdit) {
                                onAddOrEdit();
                            }
                            setModalOpen(false);
                        }}
                    />
                </FormModal>
            }
            renderChild={(user) => (
                <UserCard
                    key={user.id}
                    chargesCount={user?.chargesCount}
                    communities={user?.communities}
                    firstName={user.firstName}
                    isManager={user?.isManager}
                    lastName={user.lastName}
                    onTouch={() => {
                        navigate(`/users/${user.id}`);
                    }}
                    status={user?.status}
                />
            )}
            searchSchema={UserSchema}
            title={t('users.usersCount', { count })}
            withSearch={withSearch}
            withbutton={withbutton}
        />
    );
};

export default UserList;
