import { isDefined } from '@sparklin/react.api.utils.is-defined';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import { getSockets, socketSelect, socketLoading } from '../../store/socket/socket';
import { AppDispatch } from '../../store/store';
import BasePage from '../base/BasePage';
import SocketList from './SocketList';

const SocketsPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const company = useAppSelector(companySelect);
    const sockets = useAppSelector(socketSelect);
    const loading = useAppSelector(socketLoading);

    useEffect(() => {
        if (isDefined(company)) {
            dispatch(getSockets(company.id));
        }
    }, [company, dispatch]);

    return (
        <BasePage loaded={loading} title={t('sockets.title')}>
            <SocketList count={sockets.length} list={sockets} withSearch />
        </BasePage>
    );
};

export default SocketsPage;
