import {
    Box,
    Center,
    ChevronDownIcon,
    ChevronUpIcon,
    HStack,
    Popover,
    Pressable,
    Radio,
    Text,
    VStack,
} from 'native-base';
import React, { FC, useState } from 'react';

export type Option = {
    label: string;
    value: string;
};

interface SelectButtonProps {
    initialValue: string;
    label: string;
    onChange: (optionValue: string) => void;
    options: Option[];
}

const trigger = (triggerProps: any, label: string, isOpen: boolean) => (
    <Pressable {...triggerProps} testID="select-button">
        <Center bg="white" borderWidth="1px" rounded="3xl">
            <HStack
                alignItems="center"
                borderStyle="1px"
                height="40px"
                justifyContent="space-between"
                px={4}
                width="full"
            >
                <Text color="black" fontSize="sm">
                    {label}
                </Text>
                {isOpen ? <ChevronUpIcon color="black" /> : <ChevronDownIcon color="black" />}
            </HStack>
        </Center>
    </Pressable>
);

const SelectButton: FC<SelectButtonProps> = ({ initialValue, label, onChange, options }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <Box w="full">
            <Popover
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                onOpen={() => setIsOpen(true)}
                trigger={(triggerProps) => trigger(triggerProps, label, isOpen)}
            >
                <Popover.Content>
                    <Popover.Body px={8} py={4}>
                        <Radio.Group
                            aria-label="Select site type"
                            name="selectButton"
                            onChange={(newOption) => {
                                setIsOpen(false);
                                onChange(newOption);
                            }}
                            value={initialValue}
                        >
                            <VStack alignItems="flex-start" space={4}>
                                {options.map((option) => (
                                    <Radio key={option.value} colorScheme="blueGray" size="sm" value={option.value}>
                                        {option.label}
                                    </Radio>
                                ))}
                            </VStack>
                        </Radio.Group>
                    </Popover.Body>
                </Popover.Content>
            </Popover>
        </Box>
    );
};

export default SelectButton;
