import { IndexOptions } from 'flexsearch';
import { parsePhoneNumber } from 'libphonenumber-js';

export type SearchSchema = Array<string | ({ field: string } & IndexOptions<string>)>;

export const CommunitySchema: SearchSchema = ['name'];
export const UserSchema: SearchSchema = [
    'firstName',
    'lastName',
    'email',
    {
        field: 'phoneNumber',
        tokenize: 'full',
        encode: (phoneNumber) => {
            try {
                const parsed = parsePhoneNumber(phoneNumber);

                return parsed ? [parsed.formatNational().replace(/\D/g, ''), phoneNumber] : [phoneNumber];
            } catch (error) {
                return [phoneNumber];
            }
        },
    },
];
export const SiteSchema: SearchSchema = ['name'];
export const SocketSchema: SearchSchema = ['name'];
