import clsx from 'clsx';
import {
    Box,
    Center,
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    Column,
    Divider,
    Heading,
    HStack,
    Icon,
    Image,
    Menu as NBMenu,
    Pressable,
    Skeleton,
    Slide,
    Stack,
    Text,
    useMediaQuery,
} from 'native-base';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SCREENSIZE } from '../../global';
import { Company } from '../../pages/Users/types';
import { changeCompany, companySelect } from '../../store/auth/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { menuState, toggle } from '../../store/menu/menu';
import { profileState } from '../../store/profile/profile';
import LogoutModal from '../FormModal/LogoutModal';
import SelectLanguage from '../Select/SelectLanguage';
import MenuNavigationItem from './MenuNavigationItem/MenuNavigationItem';
import styles from './Menu.module.css';
import { ReactComponent as Business } from '../../assets/icons/business.svg';
import { ReactComponent as Community } from '../../assets/icons/community.svg';
import { ReactComponent as Dashboard } from '../../assets/icons/dashboard_black_24dp.svg';
import { ReactComponent as Sockets } from '../../assets/icons/electrical_services_black_24dp.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout_black_24dp.svg';
import { ReactComponent as Money } from '../../assets/icons/money.svg';
import { ReactComponent as Users } from '../../assets/icons/person_black_24dp.svg';
import { ReactComponent as Tools } from '../../assets/icons/tools.svg';
import avatar from '../../assets/images/avatar.png';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

export interface MenuProps {
    companies: Company[];
}

const trigger = (triggerProps: any, company?: Company) => {
    const { 'aria-expanded': ariaExpended } = triggerProps;

    return (
        <Pressable {...triggerProps} testID="company-switcher">
            <Heading alignItems="center" display="flex" fontSize="xs" fontWeight={600} mt={2}>
                <Text mr={2}>{company?.name}</Text>
                <div
                    className={clsx(styles.companySwitchIcon, {
                        [styles.open]: ariaExpended,
                    })}
                >
                    <Icon size={3}>{ariaExpended ? <ChevronUpIcon /> : <ChevronDownIcon />}</Icon>
                </div>
            </Heading>
        </Pressable>
    );
};

const Menu = ({ companies }: MenuProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const profile = useAppSelector(profileState);
    const company = useAppSelector(companySelect);
    const display = useAppSelector(menuState);
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });
    const [isOpen, setIsOpen] = useState(false);

    const menuContent = (
        <div className={styles.menuContent}>
            <Column alignItems="center">
                <Box maxW={32}>
                    <Logo height="40px" width="100%" />
                </Box>
                <Stack direction={['row', 'column']}>
                    <Center mr={['2', '0']} mt={[null, 5]}>
                        <Image alt="Avatar" size={[10, 16]} src={avatar} />
                    </Center>
                    <Center pb="3">
                        <Skeleton.Text
                            _line={{ lineHeight: 'sm' }}
                            isLoaded={profile.firstName !== undefined}
                            lines={1}
                            mt={0}
                            py={1}
                        >
                            <Heading fontSize="md" fontWeight={600} mt={4}>
                                {profile.firstName}
                            </Heading>
                        </Skeleton.Text>

                        <Skeleton.Text _line={{ lineHeight: 'lg' }} isLoaded={company !== undefined} lines={1}>
                            <NBMenu placement="bottom" trigger={(triggerProps) => trigger(triggerProps, company)}>
                                {companies.map((availableCompany) => (
                                    <NBMenu.Item
                                        key={availableCompany.id}
                                        onPress={() => {
                                            dispatch(changeCompany(availableCompany));
                                            dispatch(toggle());
                                        }}
                                    >
                                        <HStack alignItems="center" pointerEvents="auto">
                                            <Box mr={2} width={3}>
                                                {availableCompany.id === company?.id ? (
                                                    <Icon size={3}>
                                                        <CheckIcon />
                                                    </Icon>
                                                ) : null}
                                            </Box>
                                            <Text>{availableCompany.name}</Text>
                                        </HStack>
                                    </NBMenu.Item>
                                ))}
                            </NBMenu>
                        </Skeleton.Text>
                    </Center>
                </Stack>
                <Column mt={5} space={2} width="100%">
                    <MenuNavigationItem
                        icon={
                            <Icon size={5}>
                                <Dashboard />
                            </Icon>
                        }
                        name={t('menu.dashboard')}
                        path="/"
                    />
                    <Divider />
                    <MenuNavigationItem
                        icon={
                            <Icon size={5}>
                                <Community />
                            </Icon>
                        }
                        name={t('menu.communities')}
                        path="/communities"
                    />
                    <MenuNavigationItem
                        icon={
                            <Icon size={5}>
                                <Users />
                            </Icon>
                        }
                        name={t('menu.users')}
                        path="/users"
                    />
                    <MenuNavigationItem
                        icon={
                            <Icon size={5}>
                                <Business />
                            </Icon>
                        }
                        name={t('menu.sites')}
                        path="/sites"
                    />
                    <MenuNavigationItem
                        icon={
                            <Icon size={5}>
                                <Sockets />
                            </Icon>
                        }
                        name={t('menu.sockets')}
                        path="/sockets"
                    />
                    <Divider />
                    <MenuNavigationItem
                        icon={
                            <Icon size={5}>
                                <Tools />
                            </Icon>
                        }
                        name={t('menu.tools')}
                        path="/tools"
                    />
                    <MenuNavigationItem icon={<Money />} name={t('menu.Monetization')} path="/company-settings" />
                    <Divider />
                    <Pressable flexDirection="row" onPress={() => setIsOpen(true)} pb={3} pl={10} pt={3}>
                        <Icon mr={4} size={5}>
                            <LogoutIcon />
                        </Icon>
                        <Text>{t('menu.logout')}</Text>
                    </Pressable>
                    {isSmallScreen && (
                        <Box alignItems="center">
                            <SelectLanguage />
                        </Box>
                    )}
                </Column>
                {isOpen && <LogoutModal setIsOpen={() => setIsOpen(false)} />}
            </Column>
        </div>
    );

    return (
        <div className={styles.menuContainer}>
            {isSmallScreen ? (
                <Slide duration={100} in={display.show} placement="left">
                    {menuContent}
                </Slide>
            ) : (
                menuContent
            )}
        </div>
    );
};

export default Menu;
