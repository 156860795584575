import { isDefined } from '@sparklin/react.api.utils.is-defined';
import { HStack, Text, VStack, useMediaQuery } from 'native-base';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import CommunityBadge from '../../components/Badge/CommunityBadge';
import EditButton from '../../components/Button/EditButton';
import FormModal from '../../components/FormModal/FormModal';
import { SCREENSIZE } from '../../global';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import { getSiteImage } from '../../utils/site-image';
import BaseDetails from '../base/BaseDetails';
import EditSiteModalContent from '../Sites/EditSiteModalContent/EditSiteModalContent';
import getSiteTypeLabel from '../Sites/utils';
import SocketList from '../Sockets/SocketList';
import { SiteDetails } from './types';

const SiteDetailsPage = () => {
    const { id: siteId } = useParams<{ id: string }>() as { id: string };
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);
    const [site, setSite] = useState<SiteDetails | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });

    const refreshSite = () => {
        if (isDefined(company)) {
            setLoading(true);
            providers.sitesProvider
                .getSiteDetails(company.id, parseInt(siteId, 10))
                .then((siteDetails) => setSite(siteDetails))
                .catch(setError)
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        refreshSite();
    }, [company]);

    const headerChildren = site && (
        <VStack>
            <Text>{getSiteTypeLabel(t, site.type)}</Text>
            <HStack flexWrap="wrap" mt={2} space={3}>
                {site.communities.length > 0
                    ? site.communities.map((community) => (
                          <CommunityBadge key={community.id} community={community} my={2} />
                      ))
                    : null}
            </HStack>
        </VStack>
    );

    return (
        <BaseDetails
            backUrl="/sites/"
            error={error}
            headerAction={
                <EditButton
                    label={t('siteDetails.edit.button')}
                    onPress={() => setEditModalOpen(true)}
                    variant={isSmallScreen ? 'unstyled' : 'outline'}
                />
            }
            headerChildren={headerChildren}
            imageSrc={getSiteImage(site?.type)}
            isLoading={loading}
            title={site?.name}
        >
            {site && (
                <FormModal
                    isOpen={editModalOpen}
                    onClose={() => setEditModalOpen(false)}
                    testID="edit-site-modal"
                    title={t('siteDetails.edit.title')}
                >
                    <EditSiteModalContent
                        editingSite={site}
                        onSubmitted={() => {
                            refreshSite();
                            setEditModalOpen(false);
                        }}
                    />
                </FormModal>
            )}
            <SocketList count={site?.sockets.length || 0} list={site?.sockets || []} />
        </BaseDetails>
    );
};

export default SiteDetailsPage;
