import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import providers from '../../providers';
import { companySelect } from '../../store/auth/auth';
import { useAppSelector } from '../../store/hooks';
import BasePage from '../base/BasePage';
import SiteList from './SiteList';
import { Site } from './types';

const SitesPage = () => {
    const { t } = useTranslation();
    const company = useAppSelector(companySelect);
    const [loaded, setLoaded] = useState(false);
    const [sites, setSites] = useState<Site[]>([]);
    const [searchedList, setSearchedList] = useState<Site[]>([]);

    useEffect(() => {
        if (company) {
            setLoaded(false);
            providers.sitesProvider.getSites(company.id).then((data) => {
                setSites(data);
                setSearchedList(data);
                setLoaded(true);
            });
        } else {
            setLoaded(true);
        }
    }, [company]);

    return (
        <BasePage loaded={loaded} title={t('sites.title')}>
            <SiteList count={sites.length} list={searchedList} withSearch />
        </BasePage>
    );
};

export default SitesPage;
