export interface Site {
    id: SiteId;
    name: string;
    type: SiteType;
    communities?: string[];
}

export enum SiteType {
    PRIVATE = 'PRIVATE',
    SHARED = 'SHARED',
}

export enum ExportType {
    CHARGE = 'CHARGE',
    EXPENSE = 'EXPENSE',
}
