import { Icon, useMediaQuery } from 'native-base';
import React, { FC } from 'react';
import { SCREENSIZE } from '../../global';
import { ReactComponent as CardOnIcon } from '../../assets/icons/card.svg';
import { ReactComponent as CardOffIcon } from '../../assets/icons/card_off.svg';

interface CardIconProps {
    paymentEnabled?: boolean;
}

const CardIcon: FC<CardIconProps> = ({ paymentEnabled = false }) => {
    const [isSmallScreen] = useMediaQuery({
        maxWidth: SCREENSIZE,
    });
    const card = paymentEnabled ? CardOnIcon : CardOffIcon;

    return <Icon as={card} size={isSmallScreen ? 3 : 4} />;
};

export default CardIcon;
