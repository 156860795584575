import { AddIcon } from 'native-base';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GradientButton from '../../components/Button/GradientButton/GradientButton';
import CommunityCard from '../../components/Cards/CommunityCard';
import FormModal from '../../components/FormModal/FormModal';
import { Community } from '../../types';
import { CommunitySchema } from '../../utils/search-schema';
import PageList from '../base/PageList';
import { BaseListProps } from '../base/types';
import AddOrEditCommunityModalContent from './AddOrEditCommunityModalContent/AddOrEditCommunityModalContent';

interface CommunityListProps extends BaseListProps<Community> {
    count: number;
    onAddOrEdit?: VoidFunction;
    withbutton?: boolean;
}

const CommunityList: FC<CommunityListProps> = ({
    list,
    count,
    withSearch = false,
    onAddOrEdit,
    withbutton = false,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const List = PageList<Community>;
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <List
            button={
                <GradientButton
                    fontSize="sm"
                    icon={<AddIcon />}
                    label={t('communities.add.title')}
                    onClick={() => setModalOpen(true)}
                />
            }
            list={list}
            modal={
                <FormModal
                    isOpen={modalOpen}
                    onClose={() => setModalOpen(false)}
                    testID="add-user-modal"
                    title={t('communities.add.title')}
                >
                    <AddOrEditCommunityModalContent
                        onSubmitted={() => {
                            if (onAddOrEdit) {
                                onAddOrEdit();
                            }
                            setModalOpen(false);
                        }}
                    />
                </FormModal>
            }
            renderChild={({ id, name, paymentEnabled, usageMode, isDefault, isPublic }) => (
                <CommunityCard
                    key={id}
                    isDefault={isDefault}
                    isPublic={isPublic}
                    name={name}
                    onTouch={() => {
                        navigate(`/communities/${id}`);
                    }}
                    paymentEnabled={paymentEnabled}
                    usageMode={usageMode}
                />
            )}
            searchSchema={CommunitySchema}
            title={t('communities.communitiesCount', { count })}
            withSearch={withSearch}
            withbutton={withbutton}
        />
    );
};

export default CommunityList;
